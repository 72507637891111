import {Button, Card, CardContent, Grid} from "@mui/material";
import QuizQuestionSkeleton from "./QuizQuestionSkeleton";
import {constColors} from "../../../utils/consts.ts";

function QuizSkeleton() {
    return (
        <Card>
            <CardContent>
                <QuizQuestionSkeleton/>
                <Grid container marginY={1} spacing={2}>
                    <Grid item xs={12} style={{textAlign: "start"}}>
                        אנא בחר את תשובתך:
                    </Grid>
                    {["1", "2", "3", "4"].map((answer, index) => (
                        <Grid item xs={6} lg={3} key={index}>
                            <Button
                                variant="contained"
                                fullWidth
                                style={{marginBottom: '8px', backgroundColor: constColors.testButtonNotSelected}}
                            >
                                {answer}
                            </Button>
                        </Grid>
                    ))}
                    <Grid item xs={12} style={{textAlign: "center"}}>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: constColors.testButtonNotSelected,
                                width: "100%",
                                maxWidth: "400px"
                            }}
                        >
                            המשך
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default QuizSkeleton;