import React, {useEffect} from 'react';
import {Button, Grid, Link, Typography} from '@mui/material';
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";


export const AccessibilityPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle("הצהרת נגישות"));
    }, []);

    const handleWhatsAppContact = () => {
        // Code to handle WhatsApp contact
        // For simplicity, let's just open a new tab with WhatsApp Web
        window.open('https://wa.me/972557128811?text=שלום, אני מעוניין לשוחח לגבי האתר');
    };

    return (
        <Grid container spacing={2} style={{
            textAlign: "right",
            direction: "rtl",
        }} marginTop={"20px"}>
            <Grid item xs={12}>
                <>
                    <Typography
                        variant="h4"
                        gutterBottom
                    >
                        <span>&#1492;&#1510;&#1492;&#1512;&#1514; </span>
                        <span>&#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &ndash; &#1488;&#1514;&#1512; &quot;IL-Electric Cloud Solutions&#1524;</span>
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl">
                            <span>&#1492;&#1510;&#1492;&#1512;&#1514; &#1492;&#1504;&#1490;&#1497;&#1513;&#1493;&#1514; - &#1508;&#1514;&#1497;&#1495;</span>
                        </p>
                    </Typography>

                    <p dir="rtl" id="h.gjdgxs">
                        <span>IL-Electric </span>
                        <span>&#1513;&#1493;&#1488;&#1508;&#1514;</span>
                        <span>&nbsp;</span>
                        <span>&#1500;&#1505;&#1508;&#1511; &#1495;&#1493;&#1493;&#1497;&#1514; &#1513;&#1497;&#1502;&#1493;&#1513; &#1502;&#1512;&#1489;&#1497;&#1514; &#1489;&#1488;&#1514;&#1512; &#1500;&#1499;&#1500;&#1500; &#1492;&#1510;&#1497;&#1489;&#1493;&#1512; &#1500;&#1512;&#1489;&#1493;&#1514; &#1510;&#1497;&#1489;&#1493;&#1512; &#1489;&#1506;&#1500;&#1497; &#1492;&#1502;&#1493;&#1490;&#1489;&#1500;&#1493;&#1497;&#1493;&#1514;, &#1492;&#1513;&#1511;&#1506;&#1504;&#1493; &#1502;&#1488;&#1502;&#1510;&#1497;&#1501; &#1512;&#1489;&#1497;&#1501; &#1489;&#1502;&#1496;&#1512;&#1492; &#1500;&#1488;&#1508;&#1513;&#1512;, &#1500;&#1492;&#1511;&#1500; &#1493;&#1500;&#1497;&#1497;&#1506;&#1500; &#1488;&#1514; &#1492;&#1513;&#1497;&#1502;&#1493;&#1513; &#1489;&#1488;&#1514;&#1512; &#1489;&#1491;&#1490;&#1513; &#1506;&#1500; &#1510;&#1512;&#1499;&#1497; &#1510;&#1497;&#1489;&#1493;&#1512; &#1494;&#1492;. </span>
                        <span>&nbsp; </span>
                    </p>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl"><span>&#1492;&#1514;&#1493;&#1505;&#1507;</span></p>
                    </Typography>
                    <ol>
                        <li>
                            <span>&#1499;&#1495;&#1500;&#1511; &#1502;&#1492;&#1504;&#1490;&#1513;&#1514; &#1492;&#1488;&#1514;&#1512;, &#1492;&#1496;&#1502;&#1506;&#1504;&#1493; &#1488;&#1514; &#1514;&#1493;&#1505;&#1507; &#1492;&#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &quot;</span>
                            <span>ENABLE</span>
                            <span>&quot; (&#1500;&#1492;&#1500;&#1503;: &quot;</span>
                            <span>&#1492;&#1514;&#1493;&#1505;&#1507;</span>
                            <span>&quot;).</span>
                        </li>
                        <li dir="rtl">
                            <span>&#1489;&#1488;&#1502;&#1510;&#1506;&#1493;&#1514; &#1492;&#1514;&#1493;&#1505;&#1507;, &#1504;&#1497;&#1514;&#1503; &#1500;&#1492;&#1496;&#1502;&#1497;&#1506; &#1489;&#1488;&#1514;&#1512; &#1508;&#1514;&#1512;&#1493;&#1504;&#1493;&#1514; &#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &#1502;&#1514;&#1511;&#1491;&#1502;&#1497;&#1501;, &#1502;&#1489;&#1500;&#1497; &#1500;&#1506;&#1513;&#1493;&#1514; &#1513;&#1497;&#1504;&#1493;&#1497;&#1497;&#1501; &#1489;&#1511;&#1493;&#1491; &#1492;&#1488;&#1514;&#1512; )&#1492;&#1514;&#1488;&#1502;&#1492; &#1500;&#1512;&#1490;&#1493;&#1500;&#1510;&#1497;&#1497;&#1514; ADA).</span>
                        </li>
                    </ol>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl">
                            <span>&#1512;&#1502;&#1514; &#1492;&#1504;&#1490;&#1497;&#1513;&#1493;&#1514;</span></p>
                    </Typography>
                    <ol>
                        <li>
                            <span>&#1492;&#1488;&#1514;&#1512; &#1508;&#1493;&#1506;&#1500; &#1500;&#1508;&#1497; &#1500;&#1491;&#1512;&#1497;&#1513;&#1493;&#1514; &#1514;&#1511;&#1504;&#1493;&#1514; &#1513;&#1497;&#1493;&#1493;&#1497;&#1493;&#1503; &#1494;&#1499;&#1493;&#1497;&#1493;&#1514; &#1500;&#1488;&#1504;&#1513;&#1497;&#1501; &#1506;&#1501; &#1502;&#1493;&#1490;&#1489;&#1500;&#1493;&#1514; (&#1492;&#1514;&#1488;&#1502;&#1493;&#1514; &#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &#1500;&#1513;&#1497;&#1512;&#1493;&#1514;), &#1492;&#1514;&#1513;&#1506;&quot;&#1490; 2013.</span>
                        </li>
                        <li>
                            <span>&#1492;&#1514;&#1488;&#1502;&#1493;&#1514; &#1492;&#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &#1489;&#1493;&#1510;&#1506;&#1493; &#1506;&#1508;&quot;&#1497; &#1492;&#1502;&#1500;&#1510;&#1493;&#1514; &#1492;&#1514;&#1511;&#1503; &#1492;&#1497;&#1513;&#1512;&#1488;&#1500;&#1497; (&#1514;&quot;&#1497; 5568) &#1500;&#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &#1514;&#1499;&#1504;&#1497;&#1501; &#1489;&#1488;&#1497;&#1504;&#1496;&#1512;&#1504;&#1496; &#1489;&#1512;&#1502;&#1514; AA. </span>
                        </li>
                        <li dir="rtl" id="h.30j0zll">
                            <span>&#1514;&#1511;&#1503; &#1497;&#1513;&#1512;&#1488;&#1500;&#1497; &#1494;&#1492; &#1492;&#1497;&#1504;&#1493; &#1494;&#1492;&#1492; &#1500;&#1502;&#1505;&#1502;&#1498; &#1492;&#1511;&#1493;&#1493;&#1497;&#1501; &#1492;&#1502;&#1504;&#1495;&#1497;&#1501; &#1513;&#1500; &#1492;&#1488;&#1512;&#1490;&#1493;&#1503; &#1492;&#1489;&#1497;&#1504;&#1500;&#1488;&#1493;&#1502;&#1497; &#1492;&#1506;&#1493;&#1505;&#1511; &#1489;&#1492;&#1504;&#1490;&#1513;&#1514; &#1488;&#1514;&#1512;&#1497;&#1501; W3C- &#1502;&#1505;&#1502;&#1498; WCAG 2.0 .</span>
                        </li>
                    </ol>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl">
                            <span>&#1514;&#1497;&#1511;&#1493;&#1504;&#1497;&#1501; &#1493;&#1492;&#1514;&#1488;&#1502;&#1493;&#1514; &#1513;&#1489;&#1493;&#1510;&#1506;&#1493;</span>
                        </p>
                    </Typography>
                    <ol>
                        <li>
                            <span>&#1492;&#1492;&#1514;&#1488;&#1502;&#1493;&#1514; &#1502;&#1493;&#1514;&#1488;&#1502;&#1493;&#1514; &#1500;&#1514;&#1510;&#1493;&#1490;&#1492; &#1489;&#1502;&#1512;&#1489;&#1497;&#1514; &#1492;&#1491;&#1508;&#1491;&#1508;&#1504;&#1497;&#1501; &#1493;&#1499;&#1503; &#1500;&#1513;&#1497;&#1502;&#1493;&#1513; &#1489;&#1496;&#1500;&#1508;&#1493;&#1503; &#1505;&#1500;&#1493;&#1500;&#1512;&#1497;. </span>
                        </li>
                        <li dir="rtl">
                            <span>&#1489;&#1497;&#1503; &#1492;&#1497;&#1514;&#1512;, &#1489;&#1488;&#1502;&#1510;&#1506;&#1493;&#1514; &#1492;&#1514;&#1493;&#1505;&#1507;, &#1492;&#1502;&#1513;&#1514;&#1502;&#1513; &#1497;&#1493;&#1499;&#1500; &#1500;&#1492;&#1513;&#1514;&#1502;&#1513; &#1489;&#1514;&#1493;&#1505;&#1508;&#1497;&#1501; &#1499;&#1491;&#1500;&#1511;&#1502;&#1503;:</span>
                        </li>
                    </ol>
                    <ul>
                        <li dir="rtl">
                            <span>&#1504;&#1497;&#1490;&#1493;&#1491;&#1497;&#1493;&#1514; &#1510;&#1489;&#1506;&#1497;&#1501; &#1489;&#1488;&#1514;&#1512;;</span>
                        </li>
                        <li dir="rtl">
                            <span>&#1492;&#1490;&#1491;&#1500;&#1514; &#1492;&#1496;&#1511;&#1505;&#1496;;</span>
                        </li>
                        <li dir="rtl">
                            <span>&#1505;&#1502;&#1503; &#1502;&#1493;&#1490;&#1491;&#1500;;</span></li>
                        <li dir="rtl">
                            <span>&#1497;&#1497;&#1513;&#1493;&#1512; &#1492;&#1496;&#1511;&#1505;&#1496;;</span>
                        </li>
                        <li dir="rtl">
                            <span>&#1490;&#1493;&#1508;&#1503; &#1511;&#1512;&#1497;&#1488;;</span></li>
                        <li dir="rtl">
                            <span>&#1493;&#1506;&#1493;&#1491;, &#1499;&#1508;&#1497; &#1513;&#1502;&#1508;&#1493;&#1512;&#1496; &#1489;&#1514;&#1493;&#1505;&#1507; &#1489;&#1488;&#1514;&#1512;.</span>
                        </li>
                    </ul>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl"><span>&#1492;&#1495;&#1512;&#1490;&#1493;&#1514;</span></p>
                    </Typography>
                    <p dir="rtl">
                        <span>&#1495;&#1512;&#1507; &#1502;&#1488;&#1502;&#1510;&#1504;&#1493; &#1500;&#1488;&#1508;&#1513;&#1512; &#1490;&#1500;&#1497;&#1513;&#1492; &#1489;&#1488;&#1514;&#1512; &#1504;&#1490;&#1497;&#1513; &#1506;&#1489;&#1493;&#1512; &#1499;&#1500; &#1491;&#1508;&#1497; &#1492;&#1488;&#1514;&#1512;, &#1497;&#1514;&#1499;&#1503; &#1493;&#1497;&#1514;&#1490;&#1500;&#1493; &#1491;&#1508;&#1497;&#1501; &#1489;&#1488;&#1514;&#1512; &#1513;&#1496;&#1512;&#1501; &#1492;&#1493;&#1504;&#1490;&#1513;&#1493;, &#1488;&#1493; &#1513;&#1496;&#1512;&#1501; &#1504;&#1502;&#1510;&#1488; &#1492;&#1508;&#1514;&#1512;&#1493;&#1503; &#1492;&#1496;&#1499;&#1504;&#1493;&#1500;&#1493;&#1490;&#1497; &#1492;&#1502;&#1514;&#1488;&#1497;&#1501;. &#1488;&#1504;&#1493; &#1502;&#1502;&#1513;&#1497;&#1499;&#1497;&#1501; &#1489;&#1502;&#1488;&#1502;&#1510;&#1497;&#1501; &#1500;&#1513;&#1508;&#1512; &#1488;&#1514; &#1504;&#1490;&#1497;&#1513;&#1493;&#1514; &#1492;&#1488;&#1514;&#1512;, &#1499;&#1499;&#1500; &#1492;&#1488;&#1508;&#1513;&#1512;, &#1493;&#1494;&#1488;&#1514; &#1502;&#1514;&#1493;&#1498; &#1488;&#1502;&#1493;&#1504;&#1492; &#1493;&#1502;&#1495;&#1493;&#1497;&#1489;&#1493;&#1514; &#1502;&#1493;&#1505;&#1512;&#1497;&#1514; &#1500;&#1488;&#1508;&#1513;&#1512; &#1513;&#1497;&#1502;&#1493;&#1513; &#1489;&#1488;&#1514;&#1512; &#1500;&#1499;&#1500;&#1500; &#1492;&#1488;&#1493;&#1499;&#1500;&#1493;&#1505;&#1497;&#1497;&#1492; &#1500;&#1512;&#1489;&#1493;&#1514; &#1488;&#1504;&#1513;&#1497;&#1501; &#1506;&#1501; &#1502;&#1493;&#1490;&#1489;&#1500;&#1493;&#1497;&#1493;&#1514;.</span>
                    </p>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl">
                            <span>&#1508;&#1512;&#1496;&#1497; &#1488;&#1495;&#1512;&#1488;&#1497; &#1504;&#1490;&#1497;&#1513;&#1493;&#1514;</span>
                        </p>
                    </Typography>
                    <p dir="rtl" id="h.1fob9te">
                        <span>&#1513;&#1501;: &#1488;&#1489;&#1497;&#1489; &#1502;&#1502;&#1503;;</span>
                    </p>
                    <p dir="rtl"><span>&#1496;&#1500;&#1508;&#1493;&#1503;: 050-5858035;</span>
                    </p>
                    <p dir="rtl"><span>&#1491;&#1493;&#1488;&quot;&#1500;: electric.learning.il@gmail.com</span>
                    </p>

                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        <p dir="rtl">
                            <span>&#1497;&#1510;&#1497;&#1512;&#1514; &#1511;&#1513;&#1512;</span></p>
                    </Typography>
                    <ol>
                        <li dir="rtl">
                            <span>&#1504;&#1513;&#1502;&#1495; &#1500;&#1514;&#1514; &#1502;&#1506;&#1504;&#1492; &#1500;&#1499;&#1500; &#1496;&#1506;&#1504;&#1492; &#1489;&#1504;&#1493;&#1490;&#1506; &#1500;&#1504;&#1493;&#1513;&#1488;&#1497; &#1492;&#1504;&#1490;&#1497;&#1513;&#1493;&#1514;.</span>
                        </li>
                        <li dir="rtl">
                            <span>&#1506;&#1500; &#1502;&#1504;&#1514; &#1513;&#1504;&#1493;&#1499;&#1500; &#1500;&#1492;&#1514;&#1497;&#1497;&#1495;&#1505; &#1500;&#1496;&#1506;&#1504;&#1492; &#1504;&#1493;&#1491;&#1492; &#1506;&#1500; &#1508;&#1504;&#1497;&#1497;&#1492; &#1512;&#1495;&#1489;&#1492; &#1499;&#1499;&#1500; &#1513;&#1504;&#1497;&#1514;&#1503; &#1492;&#1499;&#1493;&#1500;&#1500;&#1514; &#1488;&#1514; &#1514;&#1497;&#1488;&#1493;&#1512; &#1492;&#1489;&#1506;&#1497;&#1492;, &#1488;&#1514; &#1492;&#1491;&#1508;&#1491;&#1508;&#1503; &#1489;&#1493; &#1492;&#1513;&#1514;&#1502;&#1513;&#1514;&#1501;, &#1488;&#1514; &#1492;&#1506;&#1502;&#1493;&#1491; &#1513;&#1489;&#1493; &#1504;&#1497;&#1505;&#1497;&#1514;&#1501; &#1500;&#1489;&#1510;&#1506; &#1508;&#1506;&#1493;&#1500;&#1492; &#1513;&#1500;&#1488; &#1510;&#1500;&#1495;&#1492; &#1493;&#1499;&#1497;&#1493;&#1510;&quot;&#1489;.</span>
                        </li>
                        <li dir="rtl">
                            <span>&#1488;&#1514; &#1492;&#1508;&#1504;&#1497;&#1497;&#1492; &#1504;&#1497;&#1514;&#1503; &#1500;&#1506;&#1513;&#1493;&#1514; &#1489;&#1488;&#1502;&#1510;&#1506;&#1497;&#1501; &#1492;&#1489;&#1488;&#1497;&#1501;: </span>
                        </li>
                    </ol>
                    <p dir="rtl">
                        <span>&#1499;&#1514;&#1493;&#1489;&#1514;: &#1510;&#1489;&#1497; &#1489;&#1493;&#1512;&#1504;&#1513;&#1496;&#1497;&#1497;&#1503; &#1488;&#1494;&#1493;&#1512; &#1514;&#1506;&#1513;&#1497;&#1497;&#1492; ,&nbsp;&#1489;&#1497;&#1514;&#1503;&nbsp;51, &#1497;&#1512;&#1493;&#1495;&#1501;;</span>
                    </p>
                    <p dir="rtl"><span>&#1496;&#1500;&#1508;&#1493;&#1503;: 050-5858035;</span>
                    </p>
                    <p dir="rtl">
                        <span>&#1499;&#1514;&#1493;&#1489;&#1514; &#1491;&#1493;&#1488;&#1512; &#1488;&#1500;&#1511;&#1496;&#1512;&#1493;&#1504;&#1497;: electric.learning.il@gmail.com</span>
                    </p>
                    <p dir="rtl">
                        <span>&#1506;&#1491;&#1499;&#1493;&#1503; &#1488;&#1495;&#1512;&#1493;&#1503; &#1500;&#1514;&#1511;&#1504;&#1493;&#1503;: &#1502;&#1512;&#1509; 2024.</span>
                    </p>
                    <p dir="rtl"><span>

                    </span>
                    </p>
                </>
            </Grid>
            <Grid item xs={12}>
                <Link href="/negishut.html" target="_blank">
                    קישור ישיר לדף ההצהרת נגישות
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" onClick={handleWhatsAppContact}>
                    צור קשר דרך WhatsApp
                </Button>
            </Grid>
        </Grid>
    )
        ;
};
