import { useState, useEffect } from 'react';
import {
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel, MenuItem,
    Select,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import './SaveQNA.css';
import {Question} from "../../../Interfaces/Question.ts";
import {formats, modules} from "../../../Interfaces/FormatsAndModules.ts";
import {Membership} from "../../../Interfaces/Membership.ts";
import {Category} from "../../../Interfaces/Category.ts";

function SaveQuestionsItem(props : {questionData : Question, categories: Category[], memberships: Membership[], questionIndex: number, questionsTotal: number, nextPage: (question: Question, incrementOrDecrement: number, save?: boolean) => void}) {
    const [question, setQuestion] = useState<Question>(props.questionData);
    const [showPreview, setShowPreview] = useState(false);
    const [showPreviewAnswer, setShowPreviewAnswer] = useState(false);
    const [showPreviewImage, setShowPreviewImage] = useState(true);
    const [questionDescription, setQuestionDescription] = useState<string>(props.questionData.questionDescription);
    const [questionAnswerDescription, setQuestionAnswerDescription] = useState<string>(props.questionData.answerDescription);
    const [questionSkip, setQuestionSkip] = useState<number>(1);
    const [selectedMembership, setSelectedMembership] = useState<string[]>(question.membership.map(membership => membership._id.toString()));
    const [category, setCategory] = useState<string>(question.category._id);

    useEffect(() => {
        if(question.id !== props.questionData.id){
            setQuestion(props.questionData);
            setQuestionDescription(props.questionData.questionDescription);
            setQuestionAnswerDescription(props.questionData.answerDescription);
        }

    }, [props.questionData, question]);

    const getCurrentQuestion = () => {
        return {
            ...question,
            questionDescription,
            answerDescription: questionAnswerDescription,
            membership: props.memberships.filter(membership => selectedMembership.includes(membership._id.toString())),
            category
        }
    }

    const handleMembershipChange = (event: any) => {
        setSelectedMembership(event.target.value as string[]);
    };

    const handleCategoryChange = (event: any) => {
        setCategory(event.target.value as string);
    }

    return (
            <Card>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{textAlign: "end"}}>
                            <TextField
                                id="outlined-number"
                                label="מספר שאלה"
                                type={"number"}
                                value={questionSkip}
                                onChange={(e) => setQuestionSkip(parseInt(e.target.value ))}
                                sx={{
                                    width: {
                                        xs: '100%',
                                        lg: '50%'
                                    }
                                }}
                            >

                            </TextField>
                        </Grid>
                        <Grid container spacing={1} item xs={6}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.nextPage(getCurrentQuestion(),questionSkip - 1, true)}
                                    style={{ width: "100%"}}
                                >
                                    <SaveIcon fontSize={"small"} /> שמור שאלה ודלג
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => props.nextPage(getCurrentQuestion(),questionSkip - 1, false)}
                                    style={{ width: "100%"}}
                                >
                                    דלג לשאלה
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.nextPage(getCurrentQuestion(),-1, false)}
                                style={{ width: "100%"}}
                            >
                                {' << '}
                                שאלה קודמת
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.nextPage(getCurrentQuestion(),-1, true)}
                                style={{ width: "100%"}}
                            >
                                <Typography marginLeft={1}>
                                    {`< שאלה קודמת  `}
                                </Typography>
                                <SaveIcon fontSize={"small"} />
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.nextPage(getCurrentQuestion(),1, true)}
                                style={{ width: "100%"}}
                            >
                                <SaveIcon fontSize={"small"} />
                                <Typography marginRight={1}>
                                    {` שאלה הבאה >`}
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => props.nextPage(getCurrentQuestion(),1, false)}
                                style={{ width: "100%" }}
                            >
                                שאלה הבאה
                                {' >>'}
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography marginTop={2} variant="h5" gutterBottom>
                        שאלה מספר {props.questionIndex + 1} מתוך {props.questionsTotal} <Switch checked={question.isActive} onChange={() => setQuestion({...question, isActive: !question.isActive})} /> {question.isActive ? "פעיל" : "לא פעיל" }
                    </Typography>
                    <Typography marginTop={2} variant="h6" gutterBottom>
                        מספר שאלה קטלוגי - {question.questionNumber}
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        קטגוריה - {question.category.categoryName}
                    </Typography>
                    <div>
                        <Typography variant="h6" gutterBottom>
                            תוכן השאלה
                        </Typography>
                            <div>
                                תצוגה מקדימה לתמונה
                                <Switch
                                    checked={showPreviewImage}
                                    onChange={() => setShowPreviewImage(!showPreviewImage)}
                                />
                                {showPreviewImage && (<img style={{width : "100%"}} src={"https://doa8awg86k4s0.cloudfront.net" + question.questionImage} alt="question" />)}
                            </div>

                        <ReactQuill
                            style={{
                                direction: "rtl",
                                textAlign: "right"
                            }}
                            theme="snow"
                            value={questionDescription}
                            onChange={setQuestionDescription}
                            formats={formats}
                            modules={modules}
                        />
                        <div>
                            תצוגה מקדימה
                            <Switch
                                checked={showPreview}
                                onChange={() => setShowPreview(!showPreview)}
                            />
                            {showPreview && (<div dangerouslySetInnerHTML={{__html : questionDescription}} />)}
                        </div>

                        {/*{question.question}*/}
                    </div>
                    <div>
                        <Typography variant="h6" gutterBottom>
                            תוכן התשובה
                        </Typography>
                        <ReactQuill
                            style={{
                                direction: "rtl",
                                textAlign: "right"
                            }}
                            theme="snow"
                            value={questionAnswerDescription}
                            onChange={setQuestionAnswerDescription}
                            formats={formats}
                            modules={modules}
                        />
                        <div>
                            תצוגה מקדימה
                            <Switch
                                checked={showPreviewAnswer}
                                onChange={() => setShowPreviewAnswer(!showPreviewAnswer)}
                            />
                            {showPreviewAnswer && (<div dangerouslySetInnerHTML={{__html : questionAnswerDescription}} />)}
                        </div>

                        {/*{question.question}*/}
                    </div>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>תוכנית</InputLabel>
                            <Select name="membership" value={selectedMembership} onChange={handleMembershipChange} label="Membership" multiple>
                                {props.memberships.map((membership) => (
                                    <MenuItem key={membership._id} value={membership._id}>
                                        {membership.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>קטגוריה</InputLabel>
                            <Select name="category" multiple={false} value={category} onChange={handleCategoryChange} label="Category">
                                {props.categories.map((category) => (
                                    <MenuItem key={category._id} value={category._id}>
                                        {category.categoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid container spacing={2}>
                        {["1", "2", "3", "4"].map((answer, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Button
                                    variant="contained"
                                    color={question.answers.includes(index) ? 'secondary' : "primary"}
                                    onClick={() => {
                                        setQuestion({
                                            ...question,
                                            answers: [index]
                                        })
                                    }}
                                    fullWidth
                                    style={{ marginBottom: '8px' }}
                                >
                                    {answer}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </CardContent>
            </Card>
    );
}

export default SaveQuestionsItem;