import React, {useEffect} from 'react';
import {Button, Grid, Link} from '@mui/material';
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";

export const PrivacyPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle("מדיניות פרטיות"));
    }, []);

    const handleWhatsAppContact = () => {
        // Code to handle WhatsApp contact
        // For simplicity, let's just open a new tab with WhatsApp Web
        window.open('https://wa.me/972557128811?text=שלום, אני מעוניין לשוחח לגבי האתר');
    };

    return (
        <Grid container spacing={2} style={{
            textAlign: "center",
            direction: "rtl",
        }} marginTop={"20px"}>
            <Grid item xs={12}>
                <iframe src="/privacy.html" width="100%" height="1000px"/>
            </Grid>
            <Grid item xs={12}>
                <Link href="/privacy.html" target="_blank">
                    קישור ישיר לדף מדיניות פרטיות
                </Link>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" onClick={handleWhatsAppContact}>
                    צור קשר דרך WhatsApp
                </Button>
            </Grid>
        </Grid>
    );
};
