import {useEffect, useState} from 'react';
import './CategorySelection.css';
import {Grid, Typography} from "@mui/material";
import {fetchCategories} from "../../../utils/api.ts";
import CategoryTileSkeleton from "./CategoryTileSkeleton.tsx";
import CategoryTile from "./CategoryTile.tsx";
import {Category} from "../../../Interfaces/Category.ts";
import CategoryPage from "./CategoryPage.tsx";
import {useDispatch} from "react-redux";
import {clearTitle, setTitle} from "../../../features/Main/mainWebsiteSlice.ts";

const CategorySelection = (props: { categories?: Category[], isDemo: boolean }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
    const dispatch = useDispatch();

    let isFetching = false;

    useEffect(() => {
        const run = async () => {
            if (!isFetching && categories.length === 0) {
                isFetching = true;
                await fetchCategories(setCategories);
            }
        }
        if (!props.isDemo) {
            run();
        } else {
            if (props.categories) {
                setCategories(props.categories);
            }
        }
    }, []);

    useEffect(() => {
        dispatch(clearTitle());
        dispatch(setTitle("בחירת קטגוריה"));
    }, [currentCategory]);

    if (currentCategory !== null) {
        return (
            <Grid container>
                <CategoryPage isDemo={props.isDemo} category={currentCategory} setCurrentCategory={setCurrentCategory}/>
            </Grid>
        );
    }

    const handleClickedCategory = (category: Category) => {
        setCurrentCategory(category);
    }

    if (categories.length === 0) {
        return (
            <CategoryTileSkeleton/>
        )
    }
    return (
        <Grid container>
            {props.isDemo && (
                <Typography
                    variant="h4"
                    color={"red"}
                    style={{margin: "20px auto", textAlign: "center"}}
                >
                    לקטלוג קטגוריות המלא פנה דרך דף צור קשר לווטסאפ עבור תשלום וחבילות
                </Typography>
            )}
            {categories.map((category, index) => (
                <Grid item padding={1} xs={12} md={6} lg={3} key={category._id}>
                    <CategoryTile index={index} category={category} onClick={handleClickedCategory}/>
                </Grid>
            ))}
        </Grid>
    );
};

export default CategorySelection;
