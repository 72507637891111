import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface UserDataInterface {
    email: string;
    isAdmin: boolean;
    fetched: boolean;
    packageEOL?: string;
    isTrial?: boolean;
}

const initialState: UserDataInterface = {
    email: '',
    isAdmin: false,
    fetched: false,
    isTrial: true
}

export const useDataSlice = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        setData: (state, action: PayloadAction<UserDataInterface>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.email = action.payload.email;
            state.isAdmin = action.payload.isAdmin;
            state.fetched = true;
            state.packageEOL = action.payload.packageEOL;
            state.isTrial = action.payload.packageEOL === undefined || (action.payload.packageEOL !== undefined && (new Date(action.payload.packageEOL) < new Date()));
        },
        setFetched: (state, action: PayloadAction<boolean>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.fetched = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setData, setFetched} = useDataSlice.actions

export default useDataSlice.reducer