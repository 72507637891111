import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://il-electric.net/">IL-ELECTRIC.NET&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Footer() {
    const navigate = useNavigate();
    return (
        <>
            <Divider/>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: {xs: 4, sm: 8},
                    py: {xs: 8, sm: 10},
                    textAlign: {sm: 'center', md: 'right'},
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: {xs: 'column', sm: 'row'},
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                            minWidth: {xs: '100%', sm: '60%'},
                        }}
                    >
                        <Box sx={{width: {xs: '100%', sm: '60%'}}}>
                            <Typography variant="body2" fontWeight={600} gutterBottom>
                                רשימת תפוצה
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                הרשם לרשימת התפוצה שלנו לעדכונים שבועיים ומבצעים.
                            </Typography>
                            <Stack direction="row" spacing={1} useFlexGap>
                                <TextField
                                    id="outlined-basic"
                                    hiddenLabel
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                    aria-label="הכנס/י את כתובת המייל שלך"
                                    label={'כתובת המייל שלך'}
                                    placeholder="כתובת המייל שלך"
                                />
                                <Button variant="contained" onClick={() => alert("אפשרות זו לא פעילה עדיין")}
                                        color="primary" sx={{flexShrink: 0}}>
                                    הרשם
                                </Button>
                            </Stack>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            // display: {xs: 'none', sm: 'flex'},
                            marginY: {xs: 4, sm: 0},
                            display: "flex",
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        {/*<Link color="text.secondary" onClick={() => navigate('/about-us')}>*/}
                        {/*    אודות*/}
                        {/*</Link>*/}
                        {/*<Link color="text.secondary" onClick={() => navigate('/qna')}>*/}
                        {/*    שאלות ותשובות*/}
                        {/*</Link>*/}
                    </Box>
                    <Box
                        sx={{
                            // display: {xs: 'none', sm: 'flex'},
                            display: "flex",
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Link color="text.secondary" onClick={() => navigate('/terms')}>
                            תקנון האתר
                        </Link>
                        <Link color="text.secondary" onClick={() => navigate('/privacy')}>
                            מדיניות פרטיות
                        </Link>
                        <Link color="text.secondary" onClick={() => navigate('/accessibility')}>
                            הצהרת נגישות
                        </Link>
                        <Link color="text.secondary" onClick={() => navigate('/contact')}>
                            צור קשר
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        pt: {xs: 4, sm: 8},
                        width: '100%',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <div>
                        <Link color="text.secondary" onClick={() => navigate('/terms')}>
                            תקנון האתר
                        </Link>
                        <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                            &nbsp;•&nbsp;
                        </Typography>
                        <Link color="text.secondary" onClick={() => navigate('/privacy')}>
                            מדיניות פרטיות
                        </Link>
                        <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                            &nbsp;•&nbsp;
                        </Typography>
                        <Link color="text.secondary" onClick={() => navigate('/accessibility')}>
                            הצהרת נגישות
                        </Link>
                        <Copyright/>
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="left"
                        spacing={1}
                        useFlexGap
                        sx={{
                            color: 'text.secondary',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            href="https://github.com/mui"
                            aria-label="GitHub"
                            sx={{alignSelf: 'center'}}
                        >
                            <FacebookIcon/>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://twitter.com/MaterialUI"
                            aria-label="X"
                            sx={{alignSelf: 'center'}}
                        >
                            <TwitterIcon/>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://www.linkedin.com/company/mui/"
                            aria-label="LinkedIn"
                            sx={{alignSelf: 'center'}}
                        >
                            <LinkedInIcon/>
                        </IconButton>
                    </Stack>
                </Box>
            </Container>
        </>
    );
}