import React, {useEffect} from 'react';
import {Button, Grid, Typography} from '@mui/material';
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {WhatsApp} from "@mui/icons-material";

export const ContactPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setTitle("צור קשר"));
    }, []);

    const handleWhatsAppContact = () => {
        // Code to handle WhatsApp contact
        // For simplicity, let's just open a new tab with WhatsApp Web
        window.open('https://wa.me/972505858035?text=שלום, אני מעוניין לשוחח לגבי האתר');
    };

    return (
        <Grid container spacing={2} style={{
            textAlign: "center",
            direction: "rtl",
        }} marginTop={"20px"}>
            <Grid item xs={12}>
                טלפון: 050-5858035;
            </Grid>
            <Grid item xs={12}>
                כתובת דואר אלקטרוני: electric.learning.il@gmail.com
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6">
                    לתשלום וחבילות או כל נושא אחר צור קשר בווטסאפ
                </Typography>
                <Button variant="outlined" onClick={handleWhatsAppContact}>
                    WhatsApp <WhatsApp/>
                </Button>
            </Grid>
        </Grid>
    );
};
