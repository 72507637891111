import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {QuestionsResults} from "../../../Interfaces/Question.ts";
import {fetchQuestionById, getTestByID} from "../../../utils/api.ts";
import QuizSkeleton from "./QuizSkeleton.tsx";
import {Button, Card, CardContent, Grid, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {constColors} from "../../../utils/consts.ts";


function ResultPage() {
    const navigate = useNavigate();
    const params = useParams();
    const [testResults, setTestResults] = useState<QuestionsResults | null>(null);
    const successColor = '#34EB01FF';
    const failColor = '#EB0101FF';
    const defaultColor = 'rgba(22, 62, 160, 0.5)';
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedAnswerColor, setSelectedAnswerColor] = useState<string>(defaultColor);
    const [nextQuestion, setNextQuestion] = useState<boolean>(false);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number>(-1);
    const dispatch = useDispatch();

    if (!params.id) {
        navigate('/');
    }

    const getCurrentQuestionIndex = async (questionsData: any[], index = 0) => {
        setIsLoading(true);
        const id = questionsData[index]._id;
        let questionData = questionsData[index];
        if (!(questionsData[index].questionDescription && questionsData[index].questionDescription !== "")) {
            questionData = await fetchQuestionById(id);
        }
        setTestResults({
            ...testResults!,
            test: [
                ...questionsData.slice(0, index),
                questionData,
                ...questionsData.slice(index + 1)
            ]
        });
        setIsLoading(false);
    }

    useEffect(() => {
        const getTest = async () => {
            if (!testResults) {
                const getTest: QuestionsResults = await getTestByID(params.id!);
                setTestResults(getTest);
                setIsLoading(false);
                dispatch(setTitle(`תוצאה - ${getTest.score}%`))

            }
        }
        getTest();
    }, [testResults]);

    useEffect(() => {
        if (testResults) {
            setSelectedAnswers([getUserCurrenQuestionAnswer()])
            handleCheckAnswer()
        }
    }, [currentQuestionIndex, testResults]);

    if (!testResults) {
        return <div>loading...</div>
    }

    const handleAnswerSelection = (answerIndex: number) => {
        setSelectedAnswers([answerIndex]);
    };

    const getUserCurrenQuestionAnswer = () => {
        const answersList = testResults.answers;
        const filterCurrentQuestionResult = answersList.filter((answer: any) => answer.qid === testResults.test[currentQuestionIndex]._id)
        return filterCurrentQuestionResult.length > 0 ? filterCurrentQuestionResult[0].results : -1;
    }

    const getRealCurrenQuestionAnswer = () => {
        return testResults.test[currentQuestionIndex].answers[0];
    }

    const handleCheckAnswer = () => {
        if (getRealCurrenQuestionAnswer() === getUserCurrenQuestionAnswer()) {
            setSelectedAnswerColor(successColor);
        } else {
            setSelectedAnswerColor(failColor);
            setCorrectAnswerIndex(getRealCurrenQuestionAnswer);
        }
        setNextQuestion(true);
    }

    const handleNextQuestion = async () => {
        setSelectedAnswerColor(defaultColor);
        setNextQuestion(false);
        setCorrectAnswerIndex(-1);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        await getCurrentQuestionIndex(testResults.test, currentQuestionIndex + 1);
    };

    const handlePreviousQuestion = async () => {
        setSelectedAnswerColor(defaultColor);
        setNextQuestion(false);
        setCorrectAnswerIndex(-1);
        setCurrentQuestionIndex(currentQuestionIndex - 1);
    }

    if (isLoading) {
        return <QuizSkeleton/>
    }

    return (
        <div>
            {testResults.test.length > 0 ? (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            שאלה מספר {currentQuestionIndex + 1} מתוך {testResults.test.length}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            קטגוריה
                            - {"categoryName" in testResults.test[currentQuestionIndex].category ? testResults.test[currentQuestionIndex].category.categoryName : "כללי"}
                        </Typography>
                        <div>
                            <div
                                dangerouslySetInnerHTML={{__html: testResults.test[currentQuestionIndex].questionDescription}}/>
                        </div>
                        <Grid container marginY={1} spacing={2}>
                            <Grid item xs={12} style={{textAlign: "start"}}>
                                במידה והתשובה היתה נכונה תשובתך תסומן בירוק
                                במידה והתשובה אינה נכונה תשובתך תסומן באדום והתשובה הנכונה בירוק
                            </Grid>
                            {["1", "2", "3", "4"].map((answer, index) => (
                                <Grid item xs={6} lg={3} key={index}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleAnswerSelection(index)}
                                        fullWidth
                                        style={{
                                            marginBottom: '8px',
                                            backgroundColor: correctAnswerIndex === index ? successColor : (selectedAnswers.includes(index) ? selectedAnswerColor : constColors.testButtonNotSelected)
                                        }}
                                        disabled={nextQuestion}
                                    >
                                        {answer}
                                    </Button>
                                </Grid>
                            ))}
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                {nextQuestion && <div
                                    dangerouslySetInnerHTML={{__html: testResults.test[currentQuestionIndex].answerDescription}}/>}
                            </Grid>
                            <Grid container item xs={12} spacing={1} style={{textAlign: "center"}}>
                                {currentQuestionIndex > 0 && <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="contained"
                                        onClick={handlePreviousQuestion}
                                        style={{
                                            backgroundColor: constColors.testButtonNotSelected,
                                            width: "100%",
                                            minWidth: "200px",
                                            maxWidth: "400px"
                                        }}
                                    >
                                        שאלה קודמת
                                    </Button>
                                </Grid>}
                                <Grid item xs={12} sm={currentQuestionIndex > 0 ? 6 : 12}>
                                    {!nextQuestion && <Button
                                        variant="contained"
                                        onClick={handleCheckAnswer}
                                        style={{
                                            backgroundColor: constColors.testButtonNotSelected,
                                            width: (currentQuestionIndex > 0 ? "100%" : "50%"),
                                            minWidth: "200px",
                                            maxWidth: "400px"
                                        }}
                                    >
                                        בדוק תשובה
                                    </Button>}
                                    {nextQuestion && currentQuestionIndex < testResults.test.length - 1 && <Button
                                        variant="contained"
                                        onClick={handleNextQuestion}
                                        style={{
                                            backgroundColor: constColors.testButtonNotSelected,
                                            width: (currentQuestionIndex > 0 ? "100%" : "50%"),
                                            minWidth: "200px",
                                            maxWidth: "400px"
                                        }}
                                    >
                                        המשך
                                    </Button>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Typography>Loading...</Typography>
            )}
        </div>
    );
}

export default ResultPage;