import {Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemButton, ListItemText} from "@mui/material";
import {Category} from "../../../Interfaces/Category.ts";
import {Folder} from "@mui/icons-material";

export interface SimpleDialogProps{
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
    list: Category[];
}
function CategoryDialog(props : SimpleDialogProps) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: string) => {
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open} style={{
            direction: "rtl"
        }}>
            <DialogTitle>בחר קטגוריה לעריכת שאלות</DialogTitle>
            <List sx={{ pt: 0 }}>

                {props.list.map((category:Category) => (
                    <ListItem disableGutters key={category._id}>
                        <ListItemButton onClick={() => handleListItemClick(category._id)}>
                            <ListItemAvatar>
                                <Folder style={{
                                    margin: "0 10px"
                                }}>

                                </Folder>
                            </ListItemAvatar>
                            <ListItemText primary={category.categoryName} dir={"rtl"} />
                        </ListItemButton>
                    </ListItem>
                ))}

            </List>
        </Dialog>
    );
}

export default CategoryDialog;