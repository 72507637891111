import {Grid, Skeleton} from "@mui/material";

function QuizQuestionSkeleton() {
    return (
        <Grid container marginY={1} spacing={2}>
            <Grid item xs={12} style={{textAlign: "start"}}>
                <Skeleton variant="rectangular" width={"50%"} height={15} style={{margin : 5}} />
            </Grid>
            <Grid item xs={12} style={{textAlign: "start"}}>
                <Skeleton variant="rectangular" width={"25%"} height={10} style={{margin : 5}}/>
            </Grid>
            <Grid item xs={12} style={{textAlign: "start"}}>
                <Skeleton variant="rounded" width={"100%"} height={350} style={{margin : 5}}/>
            </Grid>
        </Grid>
    );
}

export default QuizQuestionSkeleton;