import { configureStore } from '@reduxjs/toolkit'
import userDataReducer from './features/userData/userDataSlice'
import mainWebsiteReducer from './features/Main/mainWebsiteSlice'
import modalReducer from './features/ModalSlice'

export const store = configureStore({
    reducer: {
        userData: userDataReducer,
        mainWebsite: mainWebsiteReducer,
        modal: modalReducer,
    },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch