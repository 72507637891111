import React, {useEffect} from 'react';
import {Button, Grid, Link} from '@mui/material';
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {config} from "../../../utils/Config.ts";

export const TermsPage: React.FC = () => {
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const sessionToken = queryParams.get("session_token");
    const sessionState = queryParams.get("state");

    useEffect(() => {
        dispatch(setTitle("תקנון האתר ופרטיות"));
    }, []);

    const handleWhatsAppContact = () => {
        // Code to handle WhatsApp contact
        // For simplicity, let's just open a new tab with WhatsApp Web
        window.open('https://wa.me/972557128811?text=שלום, אני מעוניין לשוחח לגבי האתר');
    };

    const signTerms = (e: React.FormEvent) => {
        e.preventDefault();

        const url = `${config.apiBaseUrl}/public/terms?session_token=${sessionToken}&state=${sessionState}`;
        window.location.href = url;
    };

    return (
        <Grid container spacing={2} style={{
            textAlign: "center",
            direction: "rtl",
        }} marginTop={"20px"}>
            <Grid item xs={12}>
                <iframe src="/terms.html" width="100%" height="1000px"/>
            </Grid>
            <Grid item xs={12}>
                <Link href="/terms.html" target="_blank">
                    קישור ישיר לדף תקנון האתר
                </Link>
            </Grid>
            {sessionToken && sessionState && (
                <Grid item xs={12}>
                    <Button variant="contained" onClick={signTerms}>
                        חתימה על התקנון
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <Button variant="outlined" onClick={handleWhatsAppContact}>
                    צור קשר דרך WhatsApp
                </Button>
            </Grid>
        </Grid>
    );
};
