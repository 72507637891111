import {useEffect, useState} from 'react';
import {Autocomplete, Button, Grid, TextField} from '@mui/material';
import {getAllMemberships, getAllUsers, updateUser} from "../../../utils/api.ts";
import {User} from "../../../Interfaces/Users.ts";
import {Membership} from "../../../Interfaces/Membership.ts";
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {setModalData} from "../../../features/ModalSlice.ts";
import {useNavigate} from "react-router-dom";

const UpdateUsers = () => {
    const title = "עדכון משתמשים";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [users, setUsers] = useState<User[]>([]);
    const [packages, setPackages] = useState<Membership[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [packageId, setPackage] = useState<Membership | null>(null);
    const [packageEOL, setPackageEOL] = useState('');
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);
    const [year, setYear] = useState(0);

    useEffect(() => {
        // Fetch all users when the component mounts
        const fetchUsers = async () => {
            const response = await getAllUsers();
            setUsers(response);
        }
        const fetchPackages = async () => {
            const response = await getAllMemberships();
            setPackages(response);
        }
        if (packages.length === 0) {
            fetchPackages();
        }
        if (users.length === 0) {
            fetchUsers();
        }
        const now = new Date();// 2024-09-19T16:34:30.135Z
        const day = now.getDay();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();
        const initialDate = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}T00:00:00.000Z`;
        setPackageEOL(initialDate);

        dispatch(setTitle(title));
    }, []);

    const handleMonthChange = (value: string) => {
        setMonth(Number(value));
        updatePackageEOL("month", Number(value));
    }

    const handleDayChange = (value: string) => {
        setDay(Number(value));
        updatePackageEOL("day", Number(value));
    }

    const handleYearChange = (value: string) => {
        setYear(Number(value));
        updatePackageEOL("year", Number(value));
    }

    const updatePackageEOL = (changingEvent: string, value: number) => {
        let newEOL = '';
        switch (changingEvent) {
            case "day":
                newEOL = `${year}-${month < 10 ? `0${month}` : month}-${value < 10 ? `0${value}` : value}T23:59:00.000Z`;
                break;
            case "month":
                newEOL = `${year}-${value < 10 ? `0${value}` : value}-${day < 10 ? `0${day}` : day}T23:59:00.000Z`;
                break;
            case "year":
                newEOL = `${value}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}T23:59:00.000Z`;
                break;
        }
        console.log(newEOL);
        setPackageEOL(newEOL);
    }

    const parseEOL = (eol: string) => {
        const date = eol.split('T')[0].split('-');
        setDay(Number(date[2]));
        setMonth(Number(date[1]));
        setYear(Number(date[0]));
    }

    const handleUserChange = (user: User | null) => {
        if (!user) {
            return;
        }
        setSelectedUser(user);
        setPackageEOL(user.packageEOL);
        parseEOL(user.packageEOL);

        const packageToSet = packages.find((p) => p._id === user.packageId);
        if (!packageToSet) {
            return;
        }
        setPackage(packageToSet);
    };

    const handlePackageChange = (packageToSet: Membership | null) => {
        setPackage(packageToSet);
    };

    const handleSubmit = async () => {
        if (!selectedUser || !packageId || !packageEOL) {
            return;
        }

        const response = await updateUser(selectedUser.id, packageId._id, packageEOL);
        console.log(response);
        dispatch(setModalData({
            open: true,
            title: "הקטגוריה נוספה בהצלחה",
            body: "הקטגוריה נוספה בהצלחה",
            isErrorMessage: false,
            callback: () => navigate('/admin')
        }));
    };

    if (users.length === 0) {
        return <p>Loading...</p>;
    }

    return (
        <Grid container item spacing={2} marginTop={1} id="updateUser" width={"100%"}>
            <Grid item xs={12} md={4}>
                <Autocomplete
                    value={selectedUser}
                    options={users}
                    getOptionLabel={(option) => `${option.email} (${option.name})`}
                    onChange={(_event, newValue) => {
                        handleUserChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="משתמש"/>}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Autocomplete
                    value={packageId}
                    options={packages}
                    getOptionLabel={(option) => `${option.title}`}
                    onChange={(_event, newValue) => {
                        handlePackageChange(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="חבילה"/>}
                    fullWidth
                />
            </Grid>
            <Grid container item xs={12} md={4} spacing={1}>
                <Grid container item xs={4}>
                    <TextField
                        label="שנה"
                        value={year}
                        type={"number"}
                        onChange={(e) => handleYearChange(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid container item xs={4}>
                    <TextField
                        label="חודש"
                        value={month}
                        type={"number"}
                        onChange={(e) => handleMonthChange(e.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid container item xs={4}>
                    <TextField
                        label="יום"
                        value={day}
                        type={"number"}
                        onChange={(e) => handleDayChange(e.target.value)}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
                <Button
                    variant={"contained"}
                    onClick={() => handleSubmit()}
                    fullWidth
                >
                    עדכן משתמש
                </Button>
            </Grid>

        </Grid>
    );
};

export default UpdateUsers;