import {Box, ButtonBase, styled, Typography} from "@mui/material";
import {Category} from "../../../Interfaces/Category.ts";

const ImageButton = styled(ButtonBase)(({theme}) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({theme}) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

const calculateWeight = (str = '') => {
    str = str.toLowerCase();
    const legend = 'abcdefghijklmnopqrstuvwxyzאבגדהוזחטיכלמנסעפצקרשתךףץם';
    let weight = 0;
    const {length: l} = str;
    for (let i = 0; i < l; i++) {
        const el = str[i];
        const curr = legend.indexOf(el);
        weight += (curr + 1);
    }
    return weight / 10;
};

function CategoryTile(props: { category: Category, index: number, onClick: (category: Category) => void }) {
    const hue = Math.floor(calculateWeight(props.category.categoryName) * 360);
    const pastel = 'hsl(' + hue + ', 80%, 40%)';

    return (
        <Box sx={{display: 'flex', flexWrap: 'wrap', width: '100%'}}>
            <ImageButton
                focusRipple
                key={props.category.categoryName}
                style={{
                    width: "100%",
                }}
                onClick={() => props.onClick(props.category)}
            >
                <ImageSrc style={{backgroundColor: pastel}}/>
                <ImageBackdrop className="MuiImageBackdrop-root"/>
                <Image>
                    <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        sx={{
                            position: 'relative',
                            p: 4,
                            pt: 2,
                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                        }}
                    >
                        {props.category.categoryName}
                        <ImageMarked className="MuiImageMarked-root"/>
                    </Typography>
                </Image>
            </ImageButton>
        </Box>
    );
}

export default CategoryTile;