import React, {useEffect, useState} from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import {Category} from "../../../Interfaces/Category.ts";
import {addQuestion, fetchCategories, getAllMemberships, getHighestQNumber} from "../../../utils/api.ts";
import {formats, modules} from "../../../Interfaces/FormatsAndModules.ts";
import ReactQuill from "react-quill";
import {Membership} from "../../../Interfaces/Membership.ts";
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {setModalData} from "../../../features/ModalSlice.ts";
import {useNavigate} from "react-router-dom";

const AddNewQuestion = () => {
    const [questionNumber, setQuestionNumber] = useState(0);
    const [answers, setAnswers] = useState<number[]>([0]);
    const [answerDescription, setAnswerDescription] = useState('');
    const [questionDescription, setQuestionDescription] = useState('');
    const [category, setCategory] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<string[]>([""]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [highestQuestionNumber, setHighestQuestionNumber] = useState<number>(0);
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        const getCategories = async () => {
            await fetchCategories(setCategories);
        }
        const getHighestQuestionNumber = async () => {
            const response = await getHighestQNumber();
            setHighestQuestionNumber(response.highest);
            setQuestionNumber(response.highest);
        }
        const getMemberships = async () => {
            const membershipsResponse = await getAllMemberships();
            setMemberships(membershipsResponse);
        }
        if(!categories.length){
            getCategories();
            getHighestQuestionNumber();
            getMemberships();
        }
        dispatch(setTitle("הוספת שאלה חדשה"));
    });
    const handleQuestionNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(parseInt(event.target.value) < highestQuestionNumber){
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "מספר שאלה חייב להיות גדול ממספר השאלה הגבוה ביותר",
                isErrorMessage: true,
            }));
        }else{
            setQuestionNumber(parseInt(event.target.value, 10));
        }
    };

    const handleAnswersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(parseInt(event.target.value) > 4 || parseInt(event.target.value) < 1){
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "מספר תשובה נכונה חייב להיות בין 1 ל-4",
                isErrorMessage: true,
            }));
        }else{
            const answersInput = parseInt(event.target.value) - 1;
            setAnswers([answersInput]);
        }
    };

    const handleCategoryChange = (event: any) => {
        setCategory(event.target.value as string);
    };

    const handleMembershipChange = (event: any) => {
        // remove empty string selection
        const index = event.target.value.indexOf("");
        if (index > -1) {
            event.target.value.splice(index, 1);
        }
        setSelectedMembership(event.target.value as string[]);
    };

    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if(!questionNumber || !answers || !answerDescription || !questionDescription || !category){
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "אנא מלא את כל השדות",
                isErrorMessage: true,
            }));
            return;
        }
        const formData = {
            questionNumber,
            answers,
            answerDescription,
            questionDescription,
            category,
            isActive,
            membership: selectedMembership
        };
        // Handle form submission logic here
        addQuestion(formData).then(() => {
            dispatch(setModalData({
                open: true,
                title: "השאלה נוספה בהצלחה",
                body: "השאלה נוספה בהצלחה",
                isErrorMessage: false,
                callback: () => navigate('/admin')
            }));
        }).catch(() => {
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "השאלה לא נוספה בהצלחה",
                isErrorMessage: true,
            }));
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{
                alignItems: "center",
                textAlign: "center"
            }}>
                <Grid item xs={12}>
                    <TextField
                        label="Question Number"
                        type="number"
                        fullWidth
                        value={questionNumber}
                        onChange={handleQuestionNumberChange}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        style={{
                            direction: "rtl",
                            textAlign: "right"
                        }}
                        theme="snow"
                        value={questionDescription}
                        onChange={setQuestionDescription}
                        formats={formats}
                        modules={modules}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="מספר תשובה נכונה"
                        type="number"
                        fullWidth
                        value={answers[0] + 1}
                        onChange={handleAnswersChange}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <ReactQuill
                        style={{
                            direction: "rtl",
                            textAlign: "right"
                        }}
                        theme="snow"
                        value={answerDescription}
                        onChange={setAnswerDescription}
                        formats={formats}
                        modules={modules}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>קטגוריה</InputLabel>
                        <Select name="category" value={category} onChange={handleCategoryChange} label="Category">
                            {categories.map((category) => (
                                <MenuItem key={category._id} value={category._id}>
                                    {category.categoryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>תוכנית</InputLabel>
                        <Select name="membership" value={selectedMembership} onChange={handleMembershipChange} label="Membership" multiple>
                            {memberships.map((membership) => (
                                <MenuItem key={membership._id} value={membership._id}>
                                    {membership.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox name="isActive" checked={isActive} onChange={handleIsActiveChange} />}
                        label="האם השאלה פעילה?"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddNewQuestion;
