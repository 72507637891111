import { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import './SaveQNA.css';
import SaveQuestionItem from "./SaveQuestionItem";
import {Question} from "../../../Interfaces/Question.ts";
import {fetchCategories, fetchQuestionByNumber, getAllMemberships, saveQuestion} from "../../../utils/api.ts";
import QuizQuestionSkeleton from "../../Quiz/Questions/QuizQuestionSkeleton.tsx";
import {useSearchParams} from "react-router-dom";
import {Membership} from "../../../Interfaces/Membership.ts";
import {useDispatch} from "react-redux";
import {setModalData} from "../../../features/ModalSlice.ts";
import {Category} from "../../../Interfaces/Category.ts";

function SaveQuestionsPage() {
    const [searchParams] = useSearchParams();
    const [currentQuestion, setCurrentQuestion] = useState<Question>();
    const [count, setCount] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [memberships, setMemberships] = useState<Membership[] | null>(null);
    const [categories, setCategories] = useState<Category[] | null>(null);
    const dispatch = useDispatch();
    let sentRequest = false;

    useEffect(() => {
        if(!currentQuestion){
            if(!sentRequest){
                sentRequest = true;
                if(searchParams.has("num")){
                    fetchQuizData(parseInt(searchParams.get("num")!));
                    setCount(parseInt(searchParams.get("num")!));
                }else{
                    fetchQuizData(1);
                }
            }
        }
    }, [currentQuestion]);

    useEffect(() => {
        const getMemberships = async () => {
            const membershipsResponse = await getAllMemberships();
            setMemberships(membershipsResponse);
        }
        const getCategories = async () => {
            await fetchCategories(setCategories);
        }
        if(!memberships || !memberships.length){
            getMemberships();
        }
        if(!categories || !categories.length){
            getCategories();
        }
    });

    const updateUrl = (newIndex: string) => {
        const url = new URL(window.location.href);
        url.searchParams.set('num', newIndex);
        window.history.pushState(null, '', url.toString());
    }

    const fetchQuizData = async (qNumber : number) => {
        try {
            setLoading(true);
            let response;
            if(searchParams.has("category")){
                response = await fetchQuestionByNumber(qNumber, searchParams.get("category")!);
            }else{
                response = await fetchQuestionByNumber(qNumber);
            }

            setTotal(response.count);
            setCurrentQuestion(response.question);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const saveQuestionCall = async (question: Question, newIndex: number) => {
        if(!question) return;
        await saveQuestion(question);
        await fetchQuizData(newIndex);
    }

    const changeQuestion = async (question: Question, decrementOrIncrement: number, save: boolean = false) => {
        let newIndex = 0;
        if(decrementOrIncrement !== -1 && decrementOrIncrement !== 1) {
            if(decrementOrIncrement > total - 1){
                dispatch(setModalData({
                    open: true,
                    title: "שגיאה",
                    body: "הזן מספר קטן מספר השאלות במבחן",
                    isErrorMessage: true,
                }));
                return;
            }
            if(decrementOrIncrement < 0){
                dispatch(setModalData({
                    open: true,
                    title: "שגיאה",
                    body: "הזן מספר חיובי",
                    isErrorMessage: true,
                }));
                return;
            }
            newIndex = decrementOrIncrement;
        }else{
            if(!question) return;
            if ((count - 1) === 0 && decrementOrIncrement === -1) return;
            if ((count - 1) === total - 1 && decrementOrIncrement === 1) return;
            newIndex = (count - 1) + decrementOrIncrement;
        }

        setCount(newIndex + 1);
        updateUrl((newIndex + 1).toString());

        if(save){
            await saveQuestionCall(question, newIndex + 1);
        }else{
            await fetchQuizData(newIndex + 1);
        }
    }
    if(loading || !currentQuestion || !memberships) return (<QuizQuestionSkeleton />);

    return (
         <SaveQuestionItem categories={categories || []} questionData={currentQuestion!} questionIndex={count - 1} questionsTotal={total} memberships={memberships} nextPage={changeQuestion} />
    );
}

export default SaveQuestionsPage;