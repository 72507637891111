import {CircularProgress, CircularProgressProps, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {QuestionsResults} from "../../../Interfaces/Question.ts";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {constColors} from "../../../utils/consts.ts";

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color={constColors.testButtonNotSelected}
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

function UserTestSectionUI(props: { testResult: QuestionsResults }) {
    const navigate = useNavigate();

    return (
        <Grid container item xs={12} sm={6} lg={3}>
            <Grid container item xs={12} style={{
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                backgroundColor: "rgb(0,0,0,0.05)"
            }}>
                <Grid item xs={6} padding={2} style={{
                    alignItems: "center",
                    textAlign: "center",
                    alignSelf: "center",
                }}>
                    <CircularProgressWithLabel value={props.testResult.score}/>
                </Grid>
                <Grid item xs={6} padding={1} style={{
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    <Typography
                        fontWeight={"bold"}
                    >
                        {props.testResult.testType === "main" ? "מבחן מלא" : props.testResult.testType === "category" ? "מבחן קטגוריה" : "תרגול"}
                    </Typography>
                    <Typography
                        fontSize={12}
                    >
                        {props.testResult.createdAt.split("T")[0]}
                        <br/>
                        {props.testResult.createdAt.split("T")[1].split(".")[0]}
                    </Typography>
                    <Button
                        onClick={() => {
                            navigate("/result/" + props.testResult._id);
                        }}
                    >
                        בדוק תשובות
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default UserTestSectionUI;