import {Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

function ResultsSectionUI(props: { result: number, text: string }) {
    return (
        <Grid container item xs={12}>
            <Grid item xs={12}>
                <Typography variant="h4">
                    {props.result}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"h6"}>
                    {props.text}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default ResultsSectionUI;