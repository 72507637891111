import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Hero() {
    return (
        <Box
            id="hero"
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 14, sm: 20},
                    pb: {xs: 8, sm: 12},
                }}
            >
                <Stack sx={{width: {xs: '100%', sm: '70%'}}}>
                    <Typography
                        component="h3"
                        variant="h3"
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md: 'row'},
                            alignSelf: 'center',
                            textAlign: 'center',
                        }}
                    >
                        ערכת הכנה למבחן &nbsp;
                    </Typography>
                    <Typography
                        component="h4"
                        variant="h4"
                        sx={{
                            color: (theme) =>
                                theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                            display: 'flex',
                            flexDirection: {xs: 'column', md: 'row'},
                            alignSelf: 'center',
                            textAlign: 'center',
                        }}
                    >
                        חשמלאי / הנדסאי
                    </Typography>
                    <Typography variant="body1" textAlign="center" color="text.secondary">
                        בערכת ההכנה שלנו יצרנו <span style={{fontWeight: "bold"}}>תהליך למידה ממוקד על פי נושאים, כ-25 נושאים שונים, המורכב מ-4 שלבים – חומר עזר עבור כל נושא, תרגול ממוקד בנושא מסוים וסימולציות מלאות אשר מדמות את המבחן וגם על פי נושא</span>.
                        תהליך הלמידה יסייע לכם להפיק את המקסימום מהזמן העומד לרשותכם ללמידה למבחן, ולהבטיח למידה
                        אפקטיבית מכול מקום, מהבית ואפילו בדרך ברכב דרך הטלפון האישי שלכם. בערכת הכנה חלקנו לכם את המבחן
                        לנשואים ויצרנו לכם חומר עזר במיוחד עבור כל נושא, כך שניתן להתקדם ולחזק נקודות חלשות בנושא מסוים.
                    </Typography>
                    <Typography variant="caption" textAlign="center" sx={{opacity: 0.8}}>
                        ע״י לחיצה על הרשמה / התחברות את/ה מסכימ/ה
                        <Link href="#" color="primary">
                            {" "}לתנאי השימוש{" "}
                        </Link>
                        שלנו.
                    </Typography>
                </Stack>
                <img src={"/demo_light_1.png"} style={{
                    borderRadius: '10px',
                    width: "95%",
                    height: "98%",
                    outline: '1px solid',
                }}
                />
            </Container>
        </Box>
    );
}