// StepCard.tsx - A generic step card component
import React from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';

interface StepCardProps {
    title: string;
    description: string;
}

const StepCard: React.FC<StepCardProps> = ({title, description}) => {
    return (
        <Card variant="outlined">
            <CardContent>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body1">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

const steps = [
    {
        title: "ניתן להרשם בחינם ולנסות את הדמו שלנו",
        description: "קיימת קטגוריה 1 ו 10 שאלות נסיון כדי להבין כיצד האתר עובד"
    },
    {
        title: 'בחירת נושא וחומר עזר ממוקד',
        description: 'לאחר בחירת הנושא יהיה לכם חומר עזר ממוקד לנושא ולמבחן, מה עלכם לדעת. חומר העזר נועד לתת ולספק לכם את הידע עבור אותו נושא בשביל המבחן.',
    },
    {
        title: 'תרגול ממוקד בנושא',
        description: 'שלב זה נועד לשפר את השליטה שלכם בנושאי המבחן באמצעות תרגול ממוקד של שאלות ותשובות שלהן לפי נושאים לאחר שקראתם את חומר העזר.',
    },
    {
        title: 'ביצוע סימולציות על פי הנושא',
        description: 'הסימולציות על פי נושא מדמות בוחן של עד 20 שאלות באותו נושא שלמדתם שכולל בסופו ציון. תרגול הסימולציות על פי נושא מאפשר לבחון את רמת הידע שלכם בנושא מסוים.',
    },
    {
        title: 'ביצוע סימולציות של המבחן המלא',
        description: 'הסימולציות מדמות את המבחן שאתם צפויים לעבור מבחינת הנושאים, סוגי השאלות על פי מהנדס או הנדסאי, האינטנסיביות ומגבלת הזמן. כמו כן, תרגול הסימולציות מאפשר לבחון את ההתקדמות שהשגתם בתרגולים והידע שלכם.',
    },
];

const ApplicationSteps: React.FC = () => {
    return (
        <Grid container alignContent={"center"} textAlign={"center"} justifyContent={"center"} padding={5}>
            <Grid container item xs={12} sm={10} spacing={3}>
                {steps.map((step, index) => (
                    <Grid item md={12} xl={6} key={index}>
                        <StepCard title={step.title} description={step.description}/>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default ApplicationSteps;
