import React, {useEffect, useState} from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import {addCategory, getAllMemberships} from "../../../utils/api.ts";
import {Membership} from "../../../Interfaces/Membership.ts";
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {setModalData} from "../../../features/ModalSlice.ts";
import {useNavigate} from "react-router-dom";

const AddNewCategory = () => {
    const [categoryName, setCategoryName] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<string[]>([""]);
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        const getMemberships = async () => {
            const membershipsResponse = await getAllMemberships();
            setMemberships(membershipsResponse);
        }
        if (!memberships.length){
            getMemberships();
        }
        dispatch(setTitle("הוספת קטגוריה חדשה"));
    });
    const handleCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryName(event.target.value);
    };

    const handleMembershipChange = (event: any) => {
        // remove empty string selection
        const index = event.target.value.indexOf("");
        if (index > -1) {
            event.target.value.splice(index, 1);
        }
        setSelectedMembership(event.target.value as string[]);
    };

    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if(!categoryName){
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "אנא מלא את כל השדות",
                isErrorMessage: true,
            }));
            return;
        }
        const formData = {
            categoryName,
            isActive,
            membership: selectedMembership
        };
        // Handle form submission logic here
        addCategory(formData).then(() => {
            dispatch(setModalData({
                open: true,
                title: "הקטגוריה נוספה בהצלחה",
                body: "הקטגוריה נוספה בהצלחה",
                isErrorMessage: false,
                callback: () => navigate('/admin')
            }));
        }).catch((e) => {
            console.log(e)
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "הקטגוריה לא נוספה בהצלחה",
                isErrorMessage: true,
            }));
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{
                alignItems: "center",
                textAlign: "center"
            }}>
                <Grid item xs={12}>
                    <TextField
                        label="Category Name"
                        type="text"
                        fullWidth
                        value={categoryName}
                        onChange={handleCategoryName}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>תוכנית</InputLabel>
                        <Select name="membership" value={selectedMembership} onChange={handleMembershipChange} label="Membership" multiple>
                            {memberships.map((membership) => (
                                <MenuItem key={membership._id} value={membership._id}>
                                    {membership.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox name="isActive" checked={isActive} onChange={handleIsActiveChange} />}
                        label="האם קטגוריה פעילה?"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddNewCategory;
