export const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'direction': 'rtl'}], // this is rtl support
        ['link', 'image', 'code-block'],
        [{
            'color': ['#F00', '#0F0', '#00F', '#000', '#FFF', 'color-picker']
        }],
        ['clean'],
    ],
};

export const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'color', 'color-picker', 'direction', 'code-block'
]