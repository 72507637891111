import {Grid} from "@mui/material";
import ResultsSectionUI from "./ui/ResultsSectionUI.tsx";
import {useEffect, useState} from "react";
import {clearTitle, setTitle} from "../../features/Main/mainWebsiteSlice.ts";
import {useDispatch} from "react-redux";
import EmailSectionUI from "./ui/EmailSectionUI.tsx";
import UserTestSectionUI from "./ui/UserTestSectionUI.tsx";
import {getAllUserTests} from "../../utils/api.ts";
import {QuestionsResults} from "../../Interfaces/Question.ts";

function Profile() {
    const dispatch = useDispatch();
    const [allUserTests, setAllUserTests] = useState<QuestionsResults[]>([]);
    const [mainCount, setMainCount] = useState<number>(0);
    const [categoryCount, setCategoryCount] = useState<number>(0);
    const [practiceCount, setPracticeCount] = useState<number>(0);
    const [fetched, setFetched] = useState<boolean>(false);

    useEffect(() => {
        dispatch(clearTitle());
        dispatch(setTitle("פרופיל"));

        const getAllTest = async () => {
            const tests = await getAllUserTests();
            setAllUserTests(tests);
            setMainCount(tests.filter((test: QuestionsResults) => test.testType === "main").length);
            setCategoryCount(tests.filter((test: QuestionsResults) => test.testType === "category").length);
            setPracticeCount(tests.filter((test: QuestionsResults) => test.testType === "train").length);
            setFetched(true);
        }
        if (!fetched) {
            getAllTest()
        }
    }, [allUserTests]);

    if (!allUserTests) {
        return <div>loading...</div>
    }

    return (
        <Grid container style={{
            textAlign: "center",
        }}>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    <img style={{
                        width: "125px",
                        height: "125px",
                        borderRadius: "50%",
                        overflow: "hidden",
                        boxShadow: "0 10px 25px 0 rgba(0,0,0,.3)"

                    }}
                         alt="..."
                         src="https://demos.creative-tim.com/now-ui-kit-react/static/media/ryan.ca24473b.jpg"/>
                </Grid>
                <Grid item xs={12} style={{marginTop: "15px"}}>
                    <EmailSectionUI/>
                </Grid>
                <Grid container item
                      xs={12}
                      style={{marginTop: "0"}}
                      sx={{
                          spacing: {
                              xs: 2,
                              md: 3,
                              lg: 6,
                          }
                      }}
                >
                    <Grid item xs={12} md={4}>
                        <ResultsSectionUI result={mainCount}
                                          text={"מבחן מלא"}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ResultsSectionUI result={categoryCount}
                                          text={"מבחן קטגוריה"}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <ResultsSectionUI result={practiceCount}
                                          text={"תרגולים"}/>
                    </Grid>
                    <Grid container spacing={2} item xs={12} marginBottom={"20px"} marginTop={"20px"}>
                        {allUserTests.map((test, index) => (
                            <UserTestSectionUI key={index} testResult={test}/>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Profile;