import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface MainWebsiteInterface {
    title: string;
}

const initialState: MainWebsiteInterface = {
    title: '',
}

export const mainWebsiteSlice = createSlice({
    name: 'mainWebsite',
    initialState,
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.title = action.payload;
        },
        clearTitle: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.title = '';
        },
    },
})

// Action creators are generated for each case reducer function
export const { setTitle, clearTitle} = mainWebsiteSlice.actions

export default mainWebsiteSlice.reducer