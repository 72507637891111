import {useDispatch, useSelector} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../../store.ts";
import {ContactPage} from "./Contact.tsx";

function Homepage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const redirect = localStorage.getItem("redirect");
    const userData = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        dispatch(setTitle("דף הבית"));
        if (redirect) {
            localStorage.removeItem("redirect");
            navigate(redirect);
        }

        if (userData && userData.packageEOL && new Date(userData.packageEOL) > new Date()) {
            navigate("/categories");
        }
    });
    return (
        <ContactPage/>
    );
}

export default Homepage;