// modalSlice.ts
import {createSlice} from '@reduxjs/toolkit';

export interface ModalData {
    open: boolean;
    title: string;
    body: string;
    callback?: () => void;
    isSnackbar?: boolean;
    isErrorMessage?: boolean;
    buttons?: {
        text: string;
        callback: () => void;
    }[];
}

interface ModalState {
    modalData: ModalData;
}

const initialState: ModalState = {
    modalData: {
        open: false,
        title: "",
        body: ""
    }
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setModalData: (state, action) => {
            state.modalData = action.payload;
        }
    },
});

export const {setModalData} = modalSlice.actions;
export default modalSlice.reducer;