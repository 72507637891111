import {useAuth0} from "@auth0/auth0-react";
import {Container, Grid, PaletteMode} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Footer from "./LandingPage/Footer.tsx";
import CategorySelection from "./components/Quiz/Category/CategorySelection.tsx";
import * as React from "react";
import {ReactElement, useState} from "react";
import {setData} from "./features/userData/userDataSlice.ts";
import {useDispatch, useSelector} from "react-redux";
import QuizPage from "./components/Quiz/Questions/QuizPage.tsx";
import ResultPage from "./components/Quiz/Questions/ResultPage.tsx";
import 'react-quill/dist/quill.snow.css';
import {RootState} from "./store.ts";
import SaveQuestionsPage from "./components/Admin/Questions/SaveQuestionsPage.tsx";
import Profile from "./components/Profile/Profile.tsx";
import Homepage from "./components/Main/Homepage/Homepage.tsx";
import MainAdmin from "./components/Admin/Main/MainAdmin.tsx";
import AddNewQuestion from "./components/Admin/Questions/AddNewQuestion.tsx";
import CustomModal from "./components/CustomModal.tsx";
import AddNewCategory from "./components/Admin/Main/AddNewCategory.tsx";
import UpdateCategory from "./components/Admin/Main/UpdateCategory.tsx";
import {demoCategories} from "./utils/Demo.ts";
import CssBaseline from "@mui/material/CssBaseline";
import AppAppBar from "./components/Main/Homepage/components/AppAppB.tsx";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import getLPTheme from "./components/Main/Homepage/components/getLPTheme.tsx";
import {LandingPageApp} from "./LandingPage";
import {ContactPage} from "./components/Main/Homepage/Contact.tsx";
import {TermsPage} from "./components/Main/Homepage/Terms.tsx";
import UpdateUsers from "./components/Admin/Main/UpdateUsers.tsx";
import {AccessibilityPage} from "./components/Main/Homepage/Accessibility.tsx";
import {PrivacyPage} from "./components/Main/Homepage/Privacy.tsx";


function App() {
    const {isAuthenticated, isLoading, getAccessTokenSilently, user, logout} = useAuth0();
    const [app, setApp] = useState<ReactElement | null>(null);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     console.log(`isAuthenticated ${isAuthenticated}`, `isLoading ${isLoading}`, `error ${error}`);
    // }, [isAuthenticated]);

    if (isLoading) {
        return <div>.</div>;
        // return <div style={{
        //     height: "100vh",
        //     textAlign: "center",
        // }}>
        //     <img style={{
        //         marginTop: "25vh",
        //         width: "50vh",
        //     }} src={LoadingSvg} alt={"loading"} />
        // </div>;
    }

    // if (error) {
    //     return <ErrorApp/>;
    // }

    if (!isAuthenticated && !isLoading) {
        // loginWithRedirect().then(() => console.log("Send to login"));
        return <LandingPageApp/>;
    }
    const isAuthenticatedHandler = async () => {
        try {
            const token = await getAccessTokenSilently();
            if (user) {
                dispatch(setData({
                    fetched: true,
                    isAdmin: user && user["uri://quiz.app/jwt/claims"] && user["uri://quiz.app/jwt/claims"]["x-allowed-roles"] && user["uri://quiz.app/jwt/claims"]["x-allowed-roles"] === "admin",
                    email: user.email || "",
                    packageEOL: user["uri://quiz.app/jwt/claims"]["packageEOL"] || "1",
                }))

            }

            localStorage.setItem("token", token);
            if (!app) {
                setApp(<MainApp/>);
            }
        } catch (e) {
            console.error(e, " error");
            logout({
                logoutParams: {
                    returnTo: import.meta.env.VITE_AUTH0_REDIRECT_URI,
                }
            });
        }
    }
    if (isAuthenticated) {
        isAuthenticatedHandler().then(() => console.log("User is authenticated"));

    }

    return app;
}

const defaultTheme = createTheme({});

function MainApp() {
    const [showCustomTheme] = React.useState(true);
    const userData = useSelector((state: RootState) => state.userData);
    const [mode, setMode] = React.useState<PaletteMode>('light');
    const LPtheme = createTheme(getLPTheme(mode));

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <Container style={{minHeight: "500px", width: "100%", padding: 0, overflow: "hidden"}} dir="rtl"
                       maxWidth={false}
                       sx={(theme) => ({
                           width: '100%',
                           backgroundImage:
                               theme.palette.mode === 'light'
                                   ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                   : 'linear-gradient(#02294F, #090E10)',
                           backgroundSize: '100% 20%',
                           backgroundRepeat: 'no-repeat',
                       })}
            >
                <CustomModal/>
                <BrowserRouter>
                    {/*<AppBarV2 />*/}
                    <CssBaseline/>
                    <AppAppBar mode={mode} toggleColorMode={toggleColorMode}/>
                    <div style={{
                        marginTop: "0",
                    }}></div>
                    <Grid container paddingRight={2} paddingLeft={2} marginY={3}
                          style={{overflow: "hidden", minHeight: "50vh"}}>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} lg={10}>
                            <Routes>
                                <Route path="/" element={<Homepage/>}/>
                                <Route path="/:error" element={<div>error</div>}/>
                                <Route path="/quiz" element={<QuizPage/>}/>
                                <Route path="/terms" element={<TermsPage/>}/>
                                <Route path="/categories"
                                       element={<CategorySelection isDemo={false} categories={undefined}/>}/>
                                <Route path="/demo"
                                       element={<CategorySelection isDemo={true} categories={demoCategories}/>}/>
                                <Route path="/result/:id" element={<ResultPage/>}/>
                                <Route path="/profile" element={<Profile/>}/>
                                <Route path="/contact" element={<ContactPage/>}/>
                                {/*<Route path="/about-us" element={<AboutUs/>}/>*/}
                                {/*<Route path="/qna" element={<QnAPage/>}/>*/}
                                <Route path="/accessibility" element={<AccessibilityPage/>}/>
                                <Route path="/privacy" element={<PrivacyPage/>}/>
                                {userData.isAdmin && <Route path="/admin" element={<MainAdmin/>}/>}
                                {userData.isAdmin && <Route path="/newQuestion" element={<AddNewQuestion/>}/>}
                                {userData.isAdmin && <Route path="/newCategory" element={<AddNewCategory/>}/>}
                                {userData.isAdmin && <Route path="/updateCategory/:id" element={<UpdateCategory/>}/>}
                                {userData.isAdmin && <Route path="/saveQna" element={<SaveQuestionsPage/>}/>}
                                {userData.isAdmin && <Route path="/updateUser" element={<UpdateUsers/>}/>}
                            </Routes>
                        </Grid>
                        <Grid item xs={12} md={1}></Grid>
                    </Grid>
                    <Footer/>
                </BrowserRouter>

            </Container>
        </ThemeProvider>
    )
}

const errorMap = () => {
    if (window.location.href.includes("ConnectedDevices")) {
        return "מספר המכשירים המחוברים גדול מהמספר המותר, פנה דרך דף הצור קשר"
    }
    return "שגיאה כללית, פנה דרך דף הצור קשר";
}

export function ErrorApp() {
    return (
        <Grid item xs={12} lg={10}>
            {errorMap()}
        </Grid>
    )
}

export default App
