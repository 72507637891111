import React, {useEffect, useState} from 'react';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Typography
} from '@mui/material';
import {getAllMemberships, getCategoryById, updateCategory} from "../../../utils/api.ts";
import {Membership} from "../../../Interfaces/Membership.ts";
import {useDispatch} from "react-redux";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {setModalData} from "../../../features/ModalSlice.ts";
import {useNavigate, useParams} from "react-router-dom";
import ReactQuill from "react-quill";
import {formats, modules} from "../../../Interfaces/FormatsAndModules.ts";
import {Title} from "../../Quiz/Category/CategoryPage.tsx";

const UpdateCategory = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [categoryName, setCategoryName] = useState('');
    const [categoryDescription, setCategoryDescription] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [updateQuestions, setUpdateQuestions] = useState(false);
    const [selectedMembership, setSelectedMembership] = useState<string[]>([""]);
    const [memberships, setMemberships] = useState<Membership[]>([]);
    const dispatch = useDispatch();

    if(!params.id){
        navigate('/admin');
    }

    useEffect(() => {
        const getCategory = async () => {
            const category = await getCategoryById(params.id!);
            setCategoryName(category.categoryName);
            setIsActive(category.isActive);
            setSelectedMembership(category.membership.map((membership: Membership) => membership._id));
            setCategoryDescription(category.description);
        }
        const getMemberships = async () => {
            const membershipsResponse = await getAllMemberships();
            setMemberships(membershipsResponse);
        }
        if (!memberships.length){
            getMemberships();
        }
        if(!categoryName){
            getCategory();
        }
        dispatch(setTitle("הוספת קטגוריה חדשה"));
    });
    const handleCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCategoryName(event.target.value);
    };

    const handleMembershipChange = (event: any) => {
        // remove empty string selection
        const index = event.target.value.indexOf("");
        if (index > -1) {
            event.target.value.splice(index, 1);
        }
        setSelectedMembership(event.target.value as string[]);
    };

    const handleIsActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActive(event.target.checked);
    };

    const handleUpdateQuestionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateQuestions(event.target.checked);
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if(!categoryName){
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "אנא מלא את כל השדות",
                isErrorMessage: true,
            }));
            return;
        }
        const formData = {
            _id: params.id!,
            categoryName,
            isActive,
            membership: selectedMembership,
            description: categoryDescription,
        };
        // Handle form submission logic here
        updateCategory(formData, updateQuestions).then(() => {
            dispatch(setModalData({
                open: true,
                title: "הקטגוריה נוספה בהצלחה",
                body: "הקטגוריה נוספה בהצלחה",
                isErrorMessage: false,
                callback: () => navigate('/admin')
            }));
        }).catch((e) => {
            console.log(e)
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "הקטגוריה לא נוספה בהצלחה",
                isErrorMessage: true,
            }));
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{
                alignItems: "center",
                textAlign: "center"
            }}>
                <Grid item xs={12}>
                    <TextField
                        label="Category Name"
                        type="text"
                        fullWidth
                        value={categoryName}
                        onChange={handleCategoryName}
                        variant="outlined"
                        margin="normal"
                    />
                </Grid>
                <Grid padding={1} item xs={12}>
                    <Title>
                        <ReactQuill
                            style={{
                                direction: "rtl",
                                textAlign: "right"
                            }}
                            theme="snow"
                            value={categoryDescription}
                            onChange={setCategoryDescription}
                            formats={formats}
                            modules={modules}
                        />
                    </Title>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        gutterBottom
                        style={{
                            textAlign: "right",
                            direction: "rtl"
                        }}
                    >
                        תיאור קטגוריה
                    </Typography>
                    <div dangerouslySetInnerHTML={{__html: categoryDescription}}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel>תוכנית</InputLabel>
                        <Select name="membership" value={selectedMembership} onChange={handleMembershipChange} label="Membership" multiple>
                            {memberships.map((membership) => (
                                <MenuItem key={membership._id} value={membership._id}>
                                    {membership.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox name="isActive" checked={isActive} onChange={handleIsActiveChange} />}
                        label="האם קטגוריה פעילה?"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox name="UpdateQuestions" checked={updateQuestions} onChange={handleUpdateQuestionsChange} />}
                        label="האם לעדכן את כל השאלות?"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default UpdateCategory;
