export const addAltToDataImages = (responseString: string, altText?: string) => {
    // Create a temporary div element to hold the parsed HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = responseString;

    // Get all img elements
    const images = tempDiv.querySelectorAll('img');

    // Loop through each img element
    images.forEach(function (img) {
        // Check if src attribute contains "data:image"
        if (img.src && img.src.startsWith('data:image')) {
            // Add alt attribute with a placeholder text
            img.alt = altText ? altText : 'תמונה הקשורה לשאלה';
        }
    });

    // Convert the modified HTML back to a string
    const modifiedResponseString = tempDiv.innerHTML;

    // Clean up the temporary div
    tempDiv.remove();

    // Return the modified response string
    return modifiedResponseString;
}