import AxiosMain from 'axios';
import axiosRetry from 'axios-retry';
import {Question, QuestionDao, QuestionsAnswered} from "../Interfaces/Question.ts";
import {config} from "./Config.ts";
import {store} from "../store.ts";
import {setModalData} from "../features/ModalSlice.ts";
import {Category} from "../Interfaces/Category.ts";
import {
    checkIfHaveTestDataInLocalStorage,
    getTestDataFromLocalStorage,
    removeTestDataFromLocalStorage,
    setTestDataInLocalStorage
} from "./oldData.ts";

axiosRetry(AxiosMain, {
    retries: 3, retryDelay: (retryCount) => {
        return retryCount * 1500;
    }
});

const axios = AxiosMain.create();
axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    config.headers['Content-Type'] = 'application/json';
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    console.log("error", error.response.status);
    if (error.response.status === 403) {
        console.log("error 403");
        store.dispatch(setModalData({
            open: true,
            title: "אין לך הרשאה",
            body: "אין לך הרשאה לצפות בדף זה",
            callback: () => {
                window.location.href = "/"
            }
        }));

    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const response = error.response;
    if (response.status === 401) {
        localStorage.removeItem('token');
        window.location.reload();
    } else if (response.status === 403) {
        store.dispatch(setModalData({
            open: true,
            title: "אין לך הרשאה",
            body: "אין לך הרשאה לצפות בדף זה",
            callback: () => {
                window.location.href = "/"
            }
        }));
    } else if (response.status === 429) {
        store.dispatch(setModalData({
            open: true,
            title: "מצטערים",
            body: "נראה שיש לך הרבה בקשות, אנא נסה שוב בעוד מספר שניות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
    }
});

// Assuming you have an API endpoint to fetch quiz questions
export const fetchQuiz = async () => {
    try {
        const storageKey = 'mainQuiz';
        if (!checkIfHaveTestDataInLocalStorage(storageKey, "")) {
            const response = await axios.get(config.apiBaseUrl + '/questions/mainQuiz');
            setTestDataInLocalStorage(JSON.stringify(response.data), storageKey);
            setTestDataInLocalStorage("", storageKey + "-id")
            return response.data;
        } else {
            return new Promise<any>((resolve, reject) => {
                store.dispatch(
                    setModalData({
                        open: true,
                        title: "שאלות מבחן",
                        body: "נמצאו שאלות של מבחן ראשי שלא סיימת, האם תרצו להמשיך?",
                        buttons: [
                            {
                                text: "כן",
                                callback: () => {
                                    resolve(JSON.parse(getTestDataFromLocalStorage(storageKey) || ""));
                                }
                            },
                            {
                                text: "לא",
                                callback: async () => {
                                    try {
                                        const response = await axios.get(config.apiBaseUrl + '/questions/mainQuiz');
                                        removeTestDataFromLocalStorage(storageKey);
                                        removeTestDataFromLocalStorage(storageKey + "-id");
                                        resolve(response.data);
                                    } catch (error) {
                                        reject(error);
                                    }
                                }
                            }
                        ]
                    })
                );
            });
        }
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת השאלות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const fetchCategoryQuiz = async (categoryId: string) => {
    try {
        const storageKey = 'categoryQuiz';
        if (!checkIfHaveTestDataInLocalStorage(storageKey, categoryId)) {
            const response = await axios.get(config.apiBaseUrl + '/questions/quiz/' + categoryId);
            setTestDataInLocalStorage(JSON.stringify(response.data), storageKey);
            setTestDataInLocalStorage(categoryId, storageKey + "-id");
            return response.data;
        } else {
            return new Promise<any>((resolve, reject) => {
                store.dispatch(
                    setModalData({
                        open: true,
                        title: "שאלות מבחן",
                        body: "נמצאו שאלות של מבחן קטגוריה שלא סיימת, האם תרצו להמשיך?",
                        buttons: [
                            {
                                text: "כן",
                                callback: () => {
                                    resolve(JSON.parse(getTestDataFromLocalStorage(storageKey) || ""));
                                }
                            },
                            {
                                text: "לא",
                                callback: async () => {
                                    try {
                                        const response = await axios.get(config.apiBaseUrl + '/questions/quiz/' + categoryId);
                                        removeTestDataFromLocalStorage(storageKey);
                                        removeTestDataFromLocalStorage(storageKey + "-id");
                                        resolve(response.data);
                                    } catch (error) {
                                        reject(error);
                                    }
                                }
                            }
                        ]
                    })
                );
            });
        }
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת השאלות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const fetchCategoryTrainQuiz = async (categoryId: string) => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/questions/quiz/' + categoryId + '/train');
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת השאלות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const fetchQuestionByNumber = async (qNumber: number, categoryId?: string) => {
    try {
        let url = config.apiBaseUrl + '/questions/byNumber/' + qNumber;
        if (categoryId) {
            url += '/' + categoryId;
        }
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת השאלות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const fetchQuestionById = async (qid: string) => {
    try {
        const url = config.apiBaseUrl + '/questions/' + qid;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת השאלות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const postQuestions = async (data: QuestionsAnswered[], testType: string = "main") => {
    try {
        const response = await axios.post(config.apiBaseUrl + '/questions/mainQuiz/' + testType, data);
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת שמירת התשובות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const saveQuestion = async (data: Question) => {
    try {
        await axios.put(config.apiBaseUrl + '/questions/' + data._id, QuestionDao(data));
        store.dispatch(setModalData({
            open: true,
            title: "הוספת שאלה",
            body: "השאלה נשמרה בהצלחה",
            isSnackbar: true,
        }));
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "הוספת שאלה",
            body: "שגיאה בעת שמירת השאלה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const addQuestion = async (data: any) => {
    try {
        await axios.post(config.apiBaseUrl + '/questions', QuestionDao(data));
        store.dispatch(setModalData({
            open: true,
            title: "הוספת שאלה",
            body: "השאלה נשמרה בהצלחה",
            isSnackbar: true,
        }));
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "הוספת שאלה",
            body: "שגיאה בעת שמירת השאלה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const saveCategory = async (data: Category) => {
    try {
        await axios.put(config.apiBaseUrl + '/category/' + data._id, data);
        alert("קטגוריה נשמרה בהצלחה !");
        store.dispatch(setModalData({
            open: true,
            title: "הוספת קטגוריה",
            body: "הקטגוריה נשמרה בהצלחה",
            isSnackbar: true,
        }));
    } catch (error) {
        alert("שגיאה בעת שמירת הקטגוריה !");
        store.dispatch(setModalData({
            open: true,
            title: "הוספת קטגוריה",
            body: "שגיאה בעת שמירת הקטגוריה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const addCategory = async (data: any) => {
    try {
        await axios.post(config.apiBaseUrl + '/category', data);
        store.dispatch(setModalData({
            open: true,
            title: "הוספת קטגוריה",
            body: "הקטגוריה נשמרה בהצלחה",
            isSnackbar: true,
        }));
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "הוספת קטגוריה",
            body: "שגיאה בעת שמירת הקטגוריה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }

}

export const updateCategory = async (data: any, updateQuestions: boolean = false) => {
    try {
        const shouldUpdateQuestions = updateQuestions ? '/updateQuestions' : '';
        await axios.put(config.apiBaseUrl + '/category/' + data._id + shouldUpdateQuestions, data);
        store.dispatch(setModalData({
            open: true,
            title: "עדכון קטגוריה",
            body: "הקטגוריה עודכנה בהצלחה",
            isSnackbar: true,
        }));
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "עדכון קטגוריה",
            body: "שגיאה בעת עדכון הקטגוריה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }

}

export const getCategoryById = async (categoryId: string) => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/category/' + categoryId);
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת הקטגוריה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
    }
}

export const fetchCategories = async (setCategories: any) => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/category');
        setCategories(response.data);
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error.response.status === 403) throw error;
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת הקטגוריות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
};

export const getTestByID = async (testId: string) => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/test/' + testId);
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת המבחן",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
}

export const getAllUserTests = async () => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/test');
        return response.data;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (error.response.status === 403) throw error;

        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת המבחנים",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
}

export const getHighestQNumber = async () => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/questions/highestQuestionNumber');
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת השאלה",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
}

export const getAllMemberships = async () => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/membership');
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת החבילות",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
}

export const getAllUsers = async () => {
    try {
        const response = await axios.get(config.apiBaseUrl + '/users');
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת הבאת המשתמשים",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
}

export const updateUser = async (userId: string, packageId: string, packageEOL: string) => {
    try {
        const response = await axios.put(config.apiBaseUrl + '/users/' + userId, {packageId, packageEOL});
        return response.data;
    } catch (error) {
        store.dispatch(setModalData({
            open: true,
            title: "שגיאה",
            body: "שגיאה בעת עדכון המשתמש",
            isSnackbar: true,
            isErrorMessage: true,
        }));
        throw error;
    }
}