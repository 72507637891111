import * as React from 'react';
import {Breadcrumbs, Link, PaletteMode} from '@mui/material';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import {useSelector} from "react-redux";
import {RootState} from "../../../../store.ts";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

const logoStyle = {
    width: '40px',
    height: 'auto',
    cursor: 'pointer',
};

interface AppAppBarProps {
    mode: PaletteMode;
    toggleColorMode: () => void;
}

function AppAppBar({mode, toggleColorMode}: AppAppBarProps) {
    const {logout} = useAuth0();
    const [open, setOpen] = React.useState(false);
    const userData = useSelector((state: RootState) => state.userData);
    const mainWebsite = useSelector((state: RootState) => state.mainWebsite);

    const pages = userData.fetched ? [
        {
            name: "דף הבית",
            path: "/"
        }, {
            name: "צור קשר",
            path: "/contact"
        }
    ] : [];
    const navigate = useNavigate();

    async function onLogout() {
        logout({
            logoutParams: {
                returnTo: import.meta.env.VITE_AUTH0_REDIRECT_URI,
            }
        });
    }

    if (userData.isTrial) {
        pages.push({
            name: "דמו",
            path: "/demo"
        });
    } else {
        pages.push(
            {
                name: "פרופיל",
                path: "/profile"
            },
            {
                name: "מבחן ראשי",
                path: "/quiz"
            }, {
                name: "תרגולים",
                path: "/categories"
            }
        )
    }

    if (userData.isAdmin) {
        pages.push({
            name: "ניהול",
            path: "/admin"
        });
    }

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    return (
        <div>
            <AppBar
                // position="fixed"
                position="relative"
                sx={{
                    boxShadow: 0,
                    bgcolor: 'transparent',
                    backgroundImage: 'none',
                    mt: 2,
                }}
                dir="rtl"
            >
                <Container maxWidth="lg" dir={"rtl"}>
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            borderRadius: '999px',
                            bgcolor:
                                theme.palette.mode === 'light'
                                    ? 'rgba(255, 255, 255, 0.4)'
                                    : 'rgba(0, 0, 0, 0.4)',
                            backdropFilter: 'blur(24px)',
                            maxHeight: 40,
                            border: '1px solid',
                            borderColor: 'divider',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                    : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >
                            <img
                                src={
                                    '/electric-panel.png'
                                }
                                style={logoStyle}
                                alt="לוגו ראשי"
                            />
                            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                                {pages.map((page, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            navigate(page.path);
                                        }}
                                        sx={{py: '6px', px: '12px'}}
                                    >
                                        <Typography variant="body2" color="text.primary">
                                            {page.name}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: {xs: 'none', md: 'flex'},
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <Typography
                                variant="body2"
                                color="text.primary"
                                sx={{display: {xs: 'none', md: 'block'}}}
                            >
                                {userData.email}
                            </Typography>
                            <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode}/>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                onClick={onLogout}
                            >
                                התנתק
                            </Button>

                        </Box>
                        <Box sx={{display: {sm: '', md: 'none'}}}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{minWidth: '30px', p: '4px'}}
                            >
                                <MenuIcon/>
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)} dir={"rtl"}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'end',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode}/>
                                    </Box>
                                    {pages.map((page, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={() => {
                                                navigate(page.path);
                                            }}
                                            sx={{py: '6px', px: '12px'}}
                                        >
                                            <Typography variant="body2" color="text.primary">
                                                {page.name}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                    <Divider/>
                                    <MenuItem>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            sx={{width: '100%'}}
                                            onClick={onLogout}
                                        >
                                            התנתק
                                        </Button>
                                    </MenuItem>
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                    <Breadcrumbs aria-label="breadcrumb" style={{
                        marginRight: "16px",
                        marginTop: "10px"
                    }}>
                        <Link underline="hover" color="inherit" onClick={() => navigate('/')}>
                            ראשי
                        </Link>
                        <Typography color="text.primary">{mainWebsite.title}</Typography>
                    </Breadcrumbs>
                </Container>
            </AppBar>
        </div>
    );
}

export default AppAppBar;