import * as React from "react";
import {Container, Grid, PaletteMode} from "@mui/material";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import getLPTheme from "../components/Main/Homepage/components/getLPTheme.tsx";
import CustomModal from "../components/CustomModal.tsx";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "./Footer.tsx";
import AppAppIndexBar from "./AppAppB.tsx";
import ErrorPage from "../404.tsx";
import {ContactPage} from "../components/Main/Homepage/Contact.tsx";
import {TermsPage} from "../components/Main/Homepage/Terms.tsx";
import IndexHome from "./IndexHome.tsx";
import {ErrorApp} from "../App.tsx";
import {AccessibilityPage} from "../components/Main/Homepage/Accessibility.tsx";
import {PrivacyPage} from "../components/Main/Homepage/Privacy.tsx";

const defaultTheme = createTheme({});

export function LandingPageApp() {
    const [showCustomTheme] = React.useState(true);
    const [mode, setMode] = React.useState<PaletteMode>('light');
    const LPtheme = createTheme(getLPTheme(mode));
    const queryParams = new URLSearchParams(window.location.search);

    const toggleColorMode = () => {
        setMode((prev) => (prev === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <Container style={{minHeight: "500px", width: "100%", padding: 0, overflow: "hidden"}} dir="rtl"
                       maxWidth={false}
                       sx={(theme) => ({
                           width: '100%',
                           backgroundImage:
                               theme.palette.mode === 'light'
                                   ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                                   : 'linear-gradient(#02294F, #090E10)',
                           backgroundSize: '100% 20%',
                           backgroundRepeat: 'no-repeat',
                       })}
            >
                <CustomModal/>
                <BrowserRouter>
                    {/*<AppBarV2 />*/}
                    <CssBaseline/>
                    <AppAppIndexBar mode={mode} toggleColorMode={toggleColorMode}/>
                    <Grid container paddingRight={2} paddingLeft={2} marginY={3} marginTop={"100px"}
                          style={{overflow: "hidden", minHeight: "50vh"}}>
                        <Grid item xs={12} md={1}></Grid>
                        <Grid item xs={12} lg={10}>
                            <Routes>
                                <Route path="/" element={queryParams.get("error") ? <ErrorApp/> : <IndexHome/>}/>
                                <Route path="/contact" element={<ContactPage/>}/>
                                <Route path="/terms" element={<TermsPage/>}/>
                                <Route path="/accessibility" element={<AccessibilityPage/>}/>
                                <Route path="/privacy" element={<PrivacyPage/>}/>
                                <Route path="*" element={<ErrorPage/>}/>
                            </Routes>
                        </Grid>
                        <Grid item xs={12} md={1}></Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Footer/>
                    </Grid>
                </BrowserRouter>

            </Container>
        </ThemeProvider>
    )
}