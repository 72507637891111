import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import {Auth0Provider} from "@auth0/auth0-react";
import {Provider} from "react-redux";
import {store} from "./store.ts";

if (
    window.location.pathname !== "/" &&
    !window.location.pathname.includes("terms") &&
    !window.location.pathname.includes("contact") &&
    !window.location.pathname.includes("privacy") &&
    !window.location.pathname.includes("accessibility")
) {
    localStorage.setItem("redirect", window.location.pathname);
}

if (window.location.pathname !== "/") {
    localStorage.removeItem("redirect");
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Auth0Provider
            domain="quiz-app-il.eu.auth0.com"
            clientId="sL5rjH8tSpaiDUW6FniwiWTGJZTCejBY"
            useRefreshTokens={true}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: 'uri://quiz.app',
            }}
            cacheLocation="localstorage"
        >
            <Provider store={store}>
                <App/>
            </Provider>
        </Auth0Provider>
    </React.StrictMode>,
)
