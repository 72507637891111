export const checkIfHaveTestDataInLocalStorage = (key: string, id: string) => {
    const data = localStorage.getItem(key);
    const dataId = localStorage.getItem(key + "-id");
    if (data && dataId && JSON.parse(dataId) === id) {
        return true;
    } else {
        return false;
    }
}

export const getTestDataFromLocalStorage = (key: string) => {
    return JSON.parse(localStorage.getItem(key)!);
}

export const setTestDataInLocalStorage = (data: any, key: string) => {
    localStorage.setItem(key, JSON.stringify(data));
}

export const removeTestDataFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
}