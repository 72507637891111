// CustomModal.tsx
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Alert, Box, Button, Grid, Modal, Snackbar, Typography} from '@mui/material';
import {RootState} from "../store.ts";
import {ModalData, setModalData} from "../features/ModalSlice.ts"; // Adjust the path accordingly

interface CustomModalProps {
    // No need to pass open and onClose props
}

const CustomModal: React.FC<CustomModalProps> = () => {
    const modalData: ModalData = useSelector((state: RootState) => state.modal.modalData);
    const dispatch = useDispatch();

    const style = {
        width: 400, // Add your desired styles here
        margin: 'auto',
        mt: 4,
        p: 3,
        bgcolor: 'background.paper',
        borderRadius: 2,
    };

    const handleClose = () => {
        if (modalData.callback !== undefined) {
            modalData.callback();
        }
        dispatch(setModalData({
            open: false,
            title: "",
            body: "",
        }));
    };

    if (modalData.isSnackbar) {
        return (<Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={modalData.open}
            onClose={handleClose}
            autoHideDuration={6000}
        >
            <Alert
                dir={"rtl"}
                onClose={handleClose}
                severity={modalData.isErrorMessage ? "error" : "success"}
                variant="filled"
                sx={{width: '100%'}}
            >
                {modalData.body}
            </Alert>
        </Snackbar>)
    }

    return (
        <Modal
            open={modalData.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} textAlign={"center"}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {modalData.title}
                </Typography>
                <Typography id="modal-modal-description" sx={{mt: 2}}>
                    {modalData.body}
                </Typography>
                <Grid container spacing={2}>
                    {
                        modalData.buttons && modalData.buttons.map((button, index) => {
                            return (
                                <Grid item xs={6} key={index}>
                                    <Button variant={"contained"} onClick={() => {
                                        if (button.callback !== undefined) {
                                            button.callback();
                                        }
                                        handleClose();
                                    }}>{button.text}</Button>
                                </Grid>
                            )
                        })
                    }
                    {!modalData.buttons && modalData.callback &&
                        <Button variant={"contained"} onClick={handleClose}>אישור</Button>}
                </Grid>
            </Box>
        </Modal>
    );
};

export default CustomModal;