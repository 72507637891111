import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {RootState} from "../../../store.ts";

function EmailSectionUI() {
    const userData = useSelector((state: RootState) => state.userData);

    return (
        <>
            <Typography>
                {userData.email}
            </Typography>
            <Typography>
                תוקף מנוי - {(new Date(userData.packageEOL!)).toLocaleDateString("he-IL")}
            </Typography>
        </>
    );
}

export default EmailSectionUI;