import {useEffect, useState} from 'react';
import {Button, Card, CardContent, Grid, TextField, Typography} from '@mui/material';
import {fetchCategoryTrainQuiz, fetchQuestionById} from '../../../utils/api';
import {Category} from "../../../Interfaces/Category.ts";
import QuizSkeleton from "./QuizSkeleton.tsx";
import {Question, QuestionsAnswered} from "../../../Interfaces/Question.ts";
import {useDispatch} from "react-redux";
import {clearTitle, setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {setModalData} from "../../../features/ModalSlice.ts";
import {addAltToDataImages} from "../../../utils/utils.ts";
import {constColors} from "../../../utils/consts.ts";
import {
    checkIfHaveTestDataInLocalStorage,
    getTestDataFromLocalStorage,
    removeTestDataFromLocalStorage,
    setTestDataInLocalStorage
} from "../../../utils/oldData.ts";

function QuizPage(props: { category?: Category, train?: boolean, isDemo?: boolean, testDemoQuestions?: Question[] }) {
    const successColor = '#34EB01FF';
    const defaultColor = 'rgba(22, 62, 160, 0.5)';
    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
    const [test, setTest] = useState<QuestionsAnswered[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedAnswerColor, setSelectedAnswerColor] = useState<string>(defaultColor);
    const [correctAnswerIndex] = useState<number>(-1);
    const [jumpToIndexQ, setJumpToIndex] = useState<number>(0);
    const [showAnswer, setShowAnswer] = useState<boolean>(false);
    const dispatch = useDispatch();


    let isFetching = false;

    useEffect(() => {
        const run = async () => {
            if (!isFetching && questions.length === 0) {
                isFetching = true;
                await fetchQuizData();
            }
        };
        if (!props.isDemo) {
            run();
        } else {
            setIsLoading(false);
            setQuestions(props.testDemoQuestions || []);
            if (props.testDemoQuestions && props.testDemoQuestions.length > 0) {
                setSelectedAnswers(props.testDemoQuestions[currentQuestionIndex].answers);
                setSelectedAnswerColor(successColor);
            }
            setIsLoading(false);
            if (props.category) {
                dispatch(clearTitle());
                dispatch(setTitle("תרגול דמו - " + props.category.categoryName));
            }
        }
    });

    const getCurrentQuestionIndex = async (questionsData: any[], index = 0) => {
        setIsLoading(true);
        const id = questionsData[index]._id;
        let questionData = questionsData[index];
        if (props.isDemo) {
            setSelectedAnswers(questionData.answers);
            setSelectedAnswerColor(successColor);
            setIsLoading(false);
            return;
        }
        if (!(questionsData[index].questionDescription && questionsData[index].questionDescription !== "")) {
            questionData = await fetchQuestionById(id);
        }
        setQuestions([
            ...questionsData.slice(0, index),
            questionData,
            ...questionsData.slice(index + 1)
        ]);
        console.log()
        setSelectedAnswers(questionData.answers);
        setSelectedAnswerColor(successColor);
        setIsLoading(false);
    }

    const fetchQuizData = async () => {
        setIsLoading(true);
        try {
            if (props.category && props.train) {
                let response;
                if (!checkIfHaveTestDataInLocalStorage("train", props.category._id)) {
                    response = await fetchCategoryTrainQuiz(props.category._id);
                    setTestDataInLocalStorage(JSON.stringify(response), "train");
                    setTestDataInLocalStorage(props.category._id, "train-id")
                    setQuestions(response);
                    setIsLoading(false);
                    dispatch(clearTitle());
                    dispatch(setTitle("תרגול - " + props.category.categoryName));
                    await getCurrentQuestionIndex(response);
                    return;
                } else {
                    dispatch(setModalData({
                        open: true,
                        title: "שאלות תרגול",
                        body: "נמצאו שאלות של תרגול שלא סיימת, האם תרצו להמשיך?",
                        buttons: [
                            {
                                text: "כן",
                                callback: async () => {
                                    response = JSON.parse(getTestDataFromLocalStorage("train") || "");
                                    setQuestions(response);
                                    setIsLoading(false);
                                    dispatch(clearTitle());
                                    dispatch(setTitle("תרגול - " + props.category!.categoryName));
                                    await getCurrentQuestionIndex(response);
                                }
                            },
                            {
                                text: "לא",
                                callback: async () => {
                                    response = await fetchCategoryTrainQuiz(props.category!._id);
                                    removeTestDataFromLocalStorage("train");
                                    removeTestDataFromLocalStorage("train-id");
                                    setQuestions(response);
                                    setIsLoading(false);
                                    dispatch(clearTitle());
                                    dispatch(setTitle("תרגול - " + props.category!.categoryName));
                                    await getCurrentQuestionIndex(response);
                                }
                            }
                        ]
                    }));
                }

            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleAnswerSelection = (answerIndex: number) => {
        setSelectedAnswers([answerIndex]);
    };

    const handleNextQuestion = async () => {
        setShowAnswer(false);
        if (selectedAnswers.length === 0) {
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "בחר תשובה",
            }));
            return;
        }
        if (currentQuestionIndex === questions.length - 1) {
            const finish = {
                id: questions[currentQuestionIndex]._id,
                answers: selectedAnswers
            }
            const setLastQuestionScore = [...test];
            setLastQuestionScore.push(finish);
            return;
        } else {
            const finish = {
                id: questions[currentQuestionIndex]._id,
                answers: selectedAnswers
            }
            setTest([...test, finish]);
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            await getCurrentQuestionIndex(questions, currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = async () => {
        setShowAnswer(false);
        if (currentQuestionIndex === 0) {
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "אין שאלה קודמת",
            }));
            return;
        } else {
            const currentIndexOld = currentQuestionIndex;
            await getCurrentQuestionIndex(questions, currentQuestionIndex - 1);
            setCurrentQuestionIndex(currentIndexOld - 1);
            //get from test the user answer
            const lastQuestion = test[currentIndexOld - 1];
            //remove the last question from the test
            setTest(test.slice(0, -1));
            setSelectedAnswers(lastQuestion.answers);
        }
    };

    const jumpToIndex = async (index: number) => {
        setShowAnswer(false);
        index = index - 1;
        if (index === currentQuestionIndex) {
            return;
        }
        if (index < 0 || index >= questions.length) {
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "אין שאלה כזו",
            }));
            return;
        }
        const finish = {
            id: questions[currentQuestionIndex]._id,
            answers: selectedAnswers
        }
        setTest([...test, finish]);
        setCurrentQuestionIndex(index);
        await getCurrentQuestionIndex(questions, index);
    }

    if (isLoading) {
        return <QuizSkeleton/>
    }

    return (
        <div>
            {questions.length > 0 ? (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            שאלה מספר {currentQuestionIndex + 1} מתוך {questions.length}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            קטגוריה - {questions[currentQuestionIndex].category.categoryName}
                        </Typography>
                        <Grid container item xs={12} spacing={1} style={{textAlign: "center"}}>
                            {currentQuestionIndex > 0 && <Grid item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    onClick={handlePreviousQuestion}
                                    fullWidth
                                    style={{
                                        backgroundColor: constColors.testButtonNotSelected,
                                    }}
                                >
                                    שאלה קודמת
                                </Button>
                            </Grid>}
                            <Grid item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    onClick={handleNextQuestion}
                                    fullWidth
                                    style={{
                                        backgroundColor: constColors.testButtonNotSelected,
                                    }}
                                >
                                    המשך
                                </Button>
                            </Grid>
                            <Grid container item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    onClick={() => jumpToIndex(jumpToIndexQ)}
                                    fullWidth
                                    style={{
                                        backgroundColor: constColors.testButtonNotSelected,
                                    }}
                                >
                                    קפיצה לשאלה
                                </Button>
                            </Grid>
                            <Grid container item xs={12} sm={3}>
                                <TextField
                                    label="מספר שאלה"
                                    variant="outlined"
                                    type="number"
                                    fullWidth
                                    onChange={(e) => {
                                        setJumpToIndex(parseInt(e.target.value));
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <div>
                            {!questions[currentQuestionIndex].questionDescription || (questions[currentQuestionIndex].questionDescription && (questions[currentQuestionIndex].questionDescription === "" || questions[currentQuestionIndex].questionDescription === "<p><br></p>")) ?
                                <img style={{width: "100%", minWidth: "300px", maxWidth: "750px"}}
                                     src={questions[currentQuestionIndex].question} alt="question"/>
                                :
                                <div
                                    dangerouslySetInnerHTML={{__html: addAltToDataImages(questions[currentQuestionIndex].questionDescription)}}/>
                            }
                            {/*{questions[currentQuestionIndex].question}*/}
                        </div>
                        <Grid container marginY={1} spacing={2}>
                            <Grid item xs={12} style={{textAlign: "start"}}>
                                אנא בחר את תשובתך:
                            </Grid>
                            {["1", "2", "3", "4"].map((answer, index) => {
                                return (
                                    <Grid item xs={6} lg={3} key={index}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handleAnswerSelection(index)}
                                            fullWidth
                                            style={{
                                                marginBottom: '8px',
                                                backgroundColor: (showAnswer && correctAnswerIndex === index) ? successColor : ((showAnswer && selectedAnswers.includes(index) ? selectedAnswerColor : constColors.testButtonNotSelected)),
                                                color: "white",
                                            }}
                                            disabled
                                        >
                                            {answer}
                                        </Button>
                                    </Grid>
                                )
                            })}
                            {showAnswer && (
                                <Grid item xs={12} style={{textAlign: "center"}}>
                                    <div
                                        dangerouslySetInnerHTML={{__html: addAltToDataImages(questions[currentQuestionIndex].answerDescription)}}/>
                                </Grid>
                            )}
                            {!showAnswer && (
                                <Grid item xs={12} style={{textAlign: "center"}}>
                                    <Button
                                        variant="contained"
                                        onClick={() => setShowAnswer(true)}
                                        style={{
                                            backgroundColor: constColors.testButtonNotSelected,
                                        }}
                                    >
                                        הצג תשובה
                                    </Button>
                                </Grid>
                            )}

                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Typography>Loading...</Typography>
            )}
        </div>
    );
}

export default QuizPage;