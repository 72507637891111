import Hero from "./Hero.tsx";
import Divider from '@mui/material/Divider';
import ListOfStages from "./ListOfStages.tsx";

function IndexHome() {
    return (
        <>
            <Hero/>
            <Divider/>
            <ListOfStages/>
            {/*<Divider/>*/}
            {/*<Features/>*/}
            {/*<Divider/>*/}
        </>
    );
}

export default IndexHome;