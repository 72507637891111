import React, {useEffect, useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, styled, Switch, TextField} from "@mui/material";
import MenuTile from "./Tile";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Category} from "../../../Interfaces/Category.ts";
import QuizPage from "../Questions/QuizPage.tsx";
import TrainPage from "../Questions/TrainPage.tsx";
import {getAllMemberships, saveCategory} from "../../../utils/api.ts";
import ReactQuill from "react-quill";
import {formats, modules} from "../../../Interfaces/FormatsAndModules.ts";
import {clearTitle, setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {Membership} from "../../../Interfaces/Membership.ts";
import {demoCat1Questions} from "../../../utils/Demo.ts";
import {constColors} from "../../../utils/consts.ts";

export const Title = styled('div')({
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 'bold',
    margin: '1rem',

});

function CategoryPage(props: {
    isDemo?: boolean,
    category: Category,
    setCurrentCategory: React.Dispatch<React.SetStateAction<Category | null>>
}) {
    const [selected, setSelected] = React.useState<string>("");
    const userData = useSelector((state: RootState) => state.userData);
    const [memberships, setMemberships] = useState<Membership[] | null>(null);
    const [selectedMembership, setSelectedMembership] = useState<string[]>(props.category.membership.map(membership => membership._id.toString()));
    const dispatch = useDispatch();

    useEffect(() => {
        setCategoryTitle();
    });

    useEffect(() => {
        if (userData.isAdmin && !userData.isAdmin) {
            const getMemberships = async () => {
                const membershipsResponse = await getAllMemberships();
                setMemberships(membershipsResponse);
            }
            if (!memberships || !memberships.length) {
                getMemberships();
            }
        }
    });

    const handleClickedTest = () => {
        setSelected("test");
    }
    const handleClickedPractice = () => {
        setSelected("practice");
    }

    const handleChangeCategoryName = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setCurrentCategory({...props.category, categoryName: e.target.value});
    }

    const handleChangeCategoryDescription = (e: string) => {
        props.setCurrentCategory({...props.category, description: e});
    }

    const setCategoryTitle = () => {
        dispatch(clearTitle());
        dispatch(setTitle(props.category.categoryName));
    }

    const BackButton = (bButtonProps: { categoryPage?: boolean }) => {
        return (
            <Grid item padding={1} xs={1}>
                <Button
                    style={{backgroundColor: constColors.testButtonNotSelected, color: "white"}}
                    onClick={() => {
                        if (bButtonProps.categoryPage) {
                            setSelected("")
                            setCategoryTitle();
                        }
                        if (!bButtonProps.categoryPage) props.setCurrentCategory(null);
                    }}>
                    חזור
                </Button>
            </Grid>
        );
    }

    switch (selected) {
        case "test":
            return (
                <Grid item container xs={12}>
                    <BackButton categoryPage={true}/>
                    <Grid padding={1} item xs={12}>
                        <QuizPage category={props.category} train={false} isDemo={props.isDemo}
                                  testDemoQuestions={demoCat1Questions.sort(() => Math.random() - 0.5)}/>
                    </Grid>
                </Grid>
            )
            break;
        case "practice":
            return (
                <Grid item container xs={12}>
                    <BackButton categoryPage={true}/>
                    <Grid padding={1} item xs={12}>
                        <TrainPage category={props.category} train={true} isDemo={props.isDemo}
                                   testDemoQuestions={demoCat1Questions.sort(() => Math.random() - 0.5)}/>
                    </Grid>
                </Grid>
            )
            break;
        default:
            break;
    }

    const postCategory = async () => {
        try {
            const categoryToPost = {...props.category};
            if (memberships) {
                categoryToPost.membership = memberships.filter(membership => selectedMembership.includes(membership._id.toString()));
            }
            await saveCategory(categoryToPost);
        } catch (e) {
            console.log(e);
        }
    }

    const handleMembershipChange = (event: any) => {
        setSelectedMembership(event.target.value as string[]);
    };

    return (
        <Grid container item xs={12}>
            <BackButton/>
            <Grid padding={1} item xs={12}>
                {!userData.isAdmin && <Title>{props.category.categoryName}</Title>}
                {userData.isAdmin && false && <TextField
                    multiline={true}
                    style={{width: "100%"}}
                    value={props.category.categoryName}
                    onChange={handleChangeCategoryName}
                />}
            </Grid>
            {userData.isAdmin && false &&
                <>
                    <Grid padding={1} item xs={12}>
                        <Switch checked={props.category.isActive} onChange={() => props.setCurrentCategory({
                            ...props.category,
                            isActive: !props.category.isActive
                        })}/> {props.category.isActive ? "פעיל" : "לא פעיל"}
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined" margin="normal">
                            <InputLabel>תוכנית</InputLabel>
                            <Select name="membership" value={selectedMembership} onChange={handleMembershipChange}
                                    label="Membership" multiple>
                                {memberships && memberships!.map((membership) => (
                                    <MenuItem key={membership._id} value={membership._id}>
                                        {membership.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid padding={1} item xs={12}>
                        <Title>
                            <ReactQuill
                                style={{
                                    direction: "rtl",
                                    textAlign: "right"
                                }}
                                theme="snow"
                                value={props.category.description}
                                onChange={handleChangeCategoryDescription}
                                formats={formats}
                                modules={modules}
                            />
                        </Title>
                    </Grid>
                    <Grid padding={1} item xs={12}>
                        <Button
                            style={{backgroundColor: "#EFE0AD"}}
                            onClick={() => postCategory()}>
                            שמור
                        </Button>
                    </Grid>
                </>
            }
            <Grid item xs={12}>
                <Title>
                    מידע וחומרי עזר
                </Title>
                <div dangerouslySetInnerHTML={{__html: props.category.description}}/>
            </Grid>
            <Grid padding={1} item xs={12} md={6}>
                <MenuTile
                    title={"מבחן"}
                    usePastel={false}
                    category={props.category}
                    onClick={handleClickedTest}
                    color={"#EFE0AD"}
                />
            </Grid>
            <Grid padding={1} item xs={12} md={6}>
                <MenuTile
                    title={"תרגול"}
                    usePastel={false}
                    category={props.category}
                    onClick={handleClickedPractice}
                    color={"#EFE0AD"}
                />
            </Grid>
        </Grid>
    );
}

export default CategoryPage;