import {Membership} from "./Membership.ts";

export interface Question {
    id: string;
    _id: string;
    questionNumber: number;
    question?: string;
    questionImage: string;
    answers: number[];
    answerDescription: string;
    questionDescription: string;
    category: any;
    isActive: boolean;
    membership: Membership[];
}

export interface QuestionsAnswered {
    id: string;
    answers: number[];
}

export interface QuestionsResults {
    _id: string,
    createdAt: string,
    answers: {
        results: number,
        qid: string,
    }[],
    score: number,
    test: Question[],
    testType: "main" | "category" | "train",
}

export const QuestionDao = (question: Question) => {
    return {
        id: question.id,
        _id: question._id,
        questionNumber: question.questionNumber,
        question: question.question,
        answers: question.answers,
        answerDescription: question.answerDescription,
        questionDescription: question.questionDescription,
        category: typeof question.category === "string" ? question.category : question.category._id,
        isActive: question.isActive,
        membership: question.membership.map((m: any) => {
            // if m is a string
            if(typeof m === "string") {
                return m;
            }else {
                return m._id;
            }
        })
    }
}