import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Category} from "../../../Interfaces/Category.ts";
import {fetchCategories} from "../../../utils/api.ts";
import CategoryDialog from "./CategoryDialog.tsx";
import {setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {useDispatch} from "react-redux";

function MainAdmin() {
    const navigate = useNavigate();
    const [openCategoriesDialog, setOpenCategoriesDialog] = useState<boolean>(false);
    const [openCategoriesDialogEditCategory, setOpenCategoriesDialogEditCategory] = useState<boolean>(false);
    const [categories, setCategories] = useState<Category[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const getCategories = async () => {
            await fetchCategories(setCategories);
        }
        if (!categories.length) {
            getCategories();
        }
        dispatch(setTitle("ניהול"));
    });

    const handleClickOpen = () => {
        setOpenCategoriesDialog(true);
    };

    const handleClose = (value: string) => {
        setOpenCategoriesDialog(false);
        navigate(`/saveQna?category=${value}`);
    };

    const handleUpdateCategoryClose = (value: string) => {
        setOpenCategoriesDialogEditCategory(false);
        navigate(`/updateCategory/${value}`);
    };

    if (!categories.length) return <div>Loading...</div>;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <h1>ניהול</h1>
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        onClick={() => navigate("/saveQna")}
                    >כל השאלות</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        onClick={() => handleClickOpen()}
                    >שאלות לפי קטגוריה</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        onClick={() => navigate("/newQuestion")}
                    >הוספת שאלה</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        onClick={() => navigate("/newCategory")}
                    >הוספת קטגוריה</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        onClick={() => {
                            setOpenCategoriesDialogEditCategory(true);
                        }}
                    >עריכת קטגוריה</Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Button
                        onClick={() => navigate("/updateUser")}
                    >עדכון משתמש</Button>
                </Grid>
            </Grid>
            <CategoryDialog open={openCategoriesDialog} selectedValue={categories[0]._id} onClose={handleClose}
                            list={categories}/>
            <CategoryDialog open={openCategoriesDialogEditCategory} selectedValue={categories[0]._id}
                            onClose={handleUpdateCategoryClose} list={categories}/>
        </Grid>
    );
}

export default MainAdmin;