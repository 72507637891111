import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Card, CardContent, Grid, Typography} from '@mui/material';
import {
    fetchCategoryQuiz,
    fetchCategoryTrainQuiz,
    fetchQuestionById,
    fetchQuiz,
    postQuestions
} from '../../../utils/api';
import QuizSkeleton from "./QuizSkeleton";
import {Category} from "../../../Interfaces/Category.ts";
import {Question, QuestionsAnswered} from "../../../Interfaces/Question.ts";
import {clearTitle, setTitle} from "../../../features/Main/mainWebsiteSlice.ts";
import {useDispatch} from "react-redux";
import {setModalData} from "../../../features/ModalSlice.ts";
import {constColors} from "../../../utils/consts.ts";

function QuizPage(props: { category?: Category, train?: boolean, isDemo?: boolean, testDemoQuestions?: Question[] }) {
    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [selectedAnswers, setSelectedAnswers] = useState<number[]>([]);
    const [test, setTest] = useState<QuestionsAnswered[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fetched, setFetched] = useState<boolean>(false);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    let isFetching = false;

    const getCurrentQuestionIndex = async (questionsData: any[], index = 0) => {
        if (props.isDemo) {
            return;
        }
        setIsLoading(true);
        const id = questionsData[index]._id;
        let questionData = questionsData[index];
        if (!(questionsData[index].questionDescription && questionsData[index].questionDescription !== "")) {
            questionData = await fetchQuestionById(id);
        }
        setQuestions([
            ...questionsData.slice(0, index),
            questionData,
            ...questionsData.slice(index + 1)
        ]);
        setIsLoading(false);
    }

    useEffect(() => {
        const run = async () => {
            if (!isFetching && !fetched && questions.length === 0) {
                isFetching = true;
                await fetchQuizData();
                setFetched(true);
            }
        };
        if (!props.isDemo) {
            run();
        } else {
            setIsLoading(false);
            setQuestions(props.testDemoQuestions || []);
            dispatch(clearTitle());
            if (props.category) {
                dispatch(setTitle("מבחן דמו - " + props.category.categoryName));
            }
        }
    });

    const fetchQuizData = async () => {
        setIsLoading(true);
        try {
            if (props.category && props.train) {
                const response = await fetchCategoryTrainQuiz(props.category._id);
                setQuestions(response);
                setIsLoading(false);
                dispatch(clearTitle());
                dispatch(setTitle("תרגול - " + props.category.categoryName));
                await getCurrentQuestionIndex(response);
                return;
            } else if (props.category && !props.train) {
                const response = await fetchCategoryQuiz(props.category._id);
                setQuestions(response);
                setIsLoading(false);
                dispatch(clearTitle());
                dispatch(setTitle("מבחן - " + props.category.categoryName));
                await getCurrentQuestionIndex(response);
                return;
            }
            const response = await fetchQuiz();
            console.log(response);
            setQuestions(response);
            setIsLoading(false);
            dispatch(clearTitle());
            dispatch(setTitle("מבחן ראשי"));
            await getCurrentQuestionIndex(response);
        } catch (error) {
            console.log(error);
        }
    };

    const handleAnswerSelection = (answerIndex: number) => {
        setSelectedAnswers([answerIndex]);
    };

    const handleNextQuestion = async () => {
        if (selectedAnswers.length === 0) {
            dispatch(setModalData({
                open: true,
                title: "שגיאה",
                body: "אנא בחר תשובה",
                isErrorMessage: true,
            }));
            return;
        }
        if (currentQuestionIndex === questions.length - 1) {
            const finish = {
                id: questions[currentQuestionIndex]._id,
                answers: selectedAnswers
            }
            const setLastQuestionScore = [...test];
            setLastQuestionScore.push(finish);

            let testType = "main";
            if (props.category && props.train) {
                testType = "train";
            } else if (props.category && !props.train) {
                testType = "category";
            }
            if (props.isDemo) {
                return;
            }
            const response = await postQuestions([...test, finish], testType);
            navigate('/result/' + response._id);
        } else {
            const finish = {
                id: questions[currentQuestionIndex]._id,
                answers: selectedAnswers
            }
            setTest([...test, finish]);
            setSelectedAnswers([]); // Reset selected answers
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            await getCurrentQuestionIndex(questions, currentQuestionIndex + 1);
        }
    };

    const handlePreviousQuestion = async () => {
        const currentIndexOld = currentQuestionIndex;
        setCurrentQuestionIndex(currentIndexOld - 1);
        //get from test the user answer
        const lastQuestion = test[currentIndexOld - 1];
        //remove the last question from the test
        setTest(test.slice(0, -1));
        setSelectedAnswers(lastQuestion.answers);
    }

    if (isLoading) {
        return <QuizSkeleton/>
    }

    return (
        <div>
            {questions.length > 0 ? (
                <Card>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            שאלה מספר {currentQuestionIndex + 1} מתוך {questions.length}
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            קטגוריה - {questions[currentQuestionIndex].category.categoryName}
                        </Typography>
                        <div>
                            {!questions[currentQuestionIndex].questionDescription || (questions[currentQuestionIndex].questionDescription && (questions[currentQuestionIndex].questionDescription === "" || questions[currentQuestionIndex].questionDescription === "<p><br></p>")) ?
                                <img style={{width: "100%", minWidth: "300px", maxWidth: "750px"}}
                                     src={questions[currentQuestionIndex].question} alt="question"/>
                                :
                                <div
                                    dangerouslySetInnerHTML={{__html: questions[currentQuestionIndex].questionDescription}}/>
                            }
                            {/*{questions[currentQuestionIndex].question}*/}
                        </div>
                        <Grid container marginY={1} spacing={2}>
                            <Grid item xs={12} style={{textAlign: "start"}}>
                                אנא בחר את תשובתך:
                            </Grid>
                            {["1", "2", "3", "4"].map((answer, index) => (
                                <Grid item xs={6} lg={3} key={index}>
                                    <Button
                                        variant="contained"
                                        onClick={() => handleAnswerSelection(index)}
                                        fullWidth
                                        style={{
                                            marginBottom: '8px',
                                            backgroundColor: selectedAnswers.includes(index) ? constColors.testButtonSelected : constColors.testButtonNotSelected
                                        }}
                                    >
                                        {answer}
                                    </Button>
                                </Grid>
                            ))}
                            <Grid container item xs={12} spacing={1} style={{textAlign: "center"}}>
                                {currentQuestionIndex > 0 && <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="contained"
                                        onClick={handlePreviousQuestion}
                                        style={{
                                            backgroundColor: constColors.testButtonNotSelected,
                                            width: "100%",
                                            minWidth: "200px",
                                            maxWidth: "400px"
                                        }}
                                    >
                                        שאלה קודמת
                                    </Button>
                                </Grid>}
                                <Grid item xs={12} sm={currentQuestionIndex > 0 ? 6 : 12}>
                                    <Button
                                        variant="contained"
                                        onClick={handleNextQuestion}
                                        style={{
                                            backgroundColor: constColors.testButtonNotSelected,
                                            width: (currentQuestionIndex > 0 ? "100%" : "50%"),
                                            minWidth: "200px",
                                            maxWidth: "400px"
                                        }}
                                    >
                                        המשך
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ) : (
                <Typography>Loading...</Typography>
            )}
        </div>
    );
}

export default QuizPage;