import {Grid, Skeleton} from "@mui/material";

function CategoryTileSkeleton() {
    return (
        <Grid container>
            {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16].map((i) => (
                <Grid item padding={1} xs={12} md={6} lg={3} key={i} >
                    <Skeleton variant="rectangular" height={200} />
                </Grid>
            ))}
        </Grid>
    );
}

export default CategoryTileSkeleton;